import v8n from 'v8n'
import isemail from 'isemail'
import cpf from 'cpf'

v8n.extend({
  validEmail: () => {
    return value => isemail.validate(value)
  },
  validDocument: () => {
    return value => cpf.isValid(value)
  },
  validDate: () => {
    return value => v8n().pattern(/\d{4}-\d{2}-\d{2}/).test(value)
  },
  inList: (list) => {
    return value => list.includes(value)
  }
})
