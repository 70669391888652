import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from '../atoms'
import { Spinner, ImagePreview } from '../molecules'
import { DataTable, Confirmation, ListFilters } from '../organisms'
import {
  loadMembers,
  destroyMember
} from '../../store/actions/member'
import { navigate, links, text } from '../../utils'

class Members extends Component {
  state = {
    filters: {
      page: 1,
      limit: 10,
      search: '',
      locale_id: ''
    },
    currentId: null
  }

  componentDidMount() {
    this._loadFilters(this._loadData)
  }

  render() {
    const { list, paging, sending } = this.props.member
    const { currentId } = this.state
    const columns = this._getColumns()
    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          <Link button size='small' to={links.get('createMember')}>
            + Criar Membro da Equipe
          </Link>
        </div>
        <ListFilters
          onChange={this._onFilter}
          data={this.props.member.filters}
          filters={['search', 'locale_id']}
        />
        {sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        <DataTable
          loading={sending}
          columns={columns}
          rows={list}
          paging={paging}
          onEdit={this._onEdit}
          onDelete={this._onDelete}
          onChangePage={this._onChangePage}
        />
      </div>
    )
  }

  _loadData = () => {
    const { token } = this.props.user.user
    const { filters } = this.state
    this.props.loadMembers(token, filters)
  }

  _getColumns = () => {
    return [{
      name: 'ID',
      field: 'id'
    }, {
      name: 'Imagem',
      field: 'image_thumb_url',
      filter: (value, row) =>
        <ImagePreview
          thumbnail={value}
          image={row.image_url}
          width="50"
          className="thumbnail"
          alt={row.title}
        />
    }, {
      name: 'Nome',
      field: 'name'
    }, {
      name: 'Cargo',
      field: 'role'
    }, {
      name: 'Idioma',
      field: 'locale',
      filter: value => value ? value.name : null
    }, {
      name: 'Ativo',
      field: 'status',
      filter: value => text.yesNo(value === 'active')
    }]
  }

  _onEdit = item => {
    navigate.to({
      screen: 'editMember',
      props: this.props,
      params: { id: item.id }
    })
  }

  _onDelete = item => {
    this.setState({ currentId: item.id })
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = () => {
    const { token } = this.props.user.user
    const { currentId } = this.state
    this.setState({ currentId: null }, () => {
      this.props.destroyMember(token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onFilter = values => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    values.forEach(item => {
      filters[item.filter] = item.value
    })
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _loadFilters = callback => {
    if (this.props.member.filters) {
      const filters = {
        ...this.state.filters,
        ...this.props.member.filters
      }
      this.setState({ filters }, callback)
    } else {
      callback()
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    member: state.member
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadMembers: (token, filters) => dispatch(loadMembers(token, filters)),
    destroyMember: (token, id) => dispatch(destroyMember(token, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Members)
