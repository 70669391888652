import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FileType extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired
  }

  render() {
    return (
      <div className="file-type">
        {this.props.type}
      </div>
    )
  }
}

export default FileType
