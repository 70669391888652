import fecha from 'fecha'

export default {
  formatToChart(date) {
    const fechaDate = fecha.parse(date, 'YYYY-MM-DD')
    return fecha.format(fechaDate, 'MMMM YYYY')
  },
  formatDatetime(date, format = 'HH:mm:ss DD/MM/YYYY') {
    const fechaDate = fecha.parse(date, 'YYYY-MM-DD HH:mm:ss')
    return fecha.format(fechaDate, format)
  },
  formatDate(date, format = 'DD/MM/YYYY') {
    const fechaDate = fecha.parse(date, 'YYYY-MM-DD')
    return fecha.format(fechaDate, format)
  },
  getDate(format = 'YYYY-MM-DD') {
    return fecha.format(new Date(), format)
  }
}
