import React, { Component } from 'react'
import { Button } from '../atoms'
import { FormControl } from '../molecules'
import { translate } from '../../locales'
import { form } from '../../utils'
import { userValidator } from '../../validators'

class Login extends Component {
  static defaultProps = {
    notificate: () => {},
    login: () => {},
    sending: false
  }

  state = {
    errors: {},
    model: {
      email: '',
      password: ''
    }
  }

  render () {
    const { sending } = this.props
    const { errors, model } = this.state

    return (
      <form onSubmit={this._handleSubmit} action="#" className="login-form">
        <FormControl
          errors={form.getErrors('email', errors)}
          label={translate('user.email')}
          name='email'
          type='email'
          value={model.email}
          onChange={value => this._onChange('email', value)}
        />
        <FormControl
          errors={form.getErrors('password', errors)}
          label={translate('user.password')}
          name='password'
          type='password'
          value={model.password}
          onChange={value => this._onChange('password', value)}
        />
        <div className="form__buttons">
          <Button
            type='submit'
            size='small'
            disabled={sending}
            spinner={sending}
          >
            {translate('actions.enter')}
          </Button>
        </div>
      </form>
    )
  }

  _onChange = (name, value) => {
    const model = {
      ...this.state.model,
      [name]: value
    }
    this.setState({ model })
  }

  _handleSubmit = event => {
    event.preventDefault()
    this.setState({ errors: {} }, () => {
      const data = { ...this.state.model }
      userValidator.login(data).then(result => {
        if (result.success) {
          this.props.login(data)
        } else {
          this.setState({ errors: result.errors })
        }
      }).catch(error => {
        this.props.notificate(error.message)
      })
    })
  }
}

export default Login
