import { api, listFilters } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect
} from './'

const identifier = 'member'

const _setMembers = data => ({ type: 'SET_MEMBERS', data })
const _setMemberFilters = data => ({ type: 'SET_MEMBER_FILTERS', data })
const _setMember = data => ({ type: 'SET_MEMBER', data })
const _updateMember = data => ({ type: 'UPDATE_MEMBER', data })

export const loadMembers = (token, filters = {}) => {
  return dispatch => {
    if (listFilters.saveFilters(filters)) {
      dispatch(_setMemberFilters(filters))
    }
    dispatch(beforeSend(identifier))
    return api.get('admin/members', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setMembers(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadMember = (token, id) => {
  return dispatch => {
    dispatch(_setMember(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/members/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setMember(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateMember = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.put(`admin/members/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch(_updateMember(result.data))
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'members' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createMember = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/members', data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'members' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const destroyMember = (token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/members/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
