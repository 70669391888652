import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '../molecules'
import { Button } from '../atoms'
import { contentValidator } from '../../validators'
import { form, date, localeUtils } from '../../utils'

export default class ContentForm extends Component {
  static propTypes = {
    locales: PropTypes.arrayOf(PropTypes.object),
    parents: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.object),
    members: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.object,
    sending: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onChangeLocale: PropTypes.func.isRequired,
    notificate: PropTypes.func.isRequired
  }

  state = {
    model: {
      locale_id: 1,
      parent_id: null,
      category_id: '',
      image: '',
      date: '',
      title: '',
      summary: '',
      text: '',
      pdf: '',
      link: '',
      home_description: '',
      home_logo: '',
      is_expert: false,
      status: true,
      members: []
    },
    errors: {}
  }

  fields = [{
    label: 'Idioma',
    field: 'locale_id',
    type: 'select'
  }, {
    label: 'Registro do Idioma Principal',
    field: 'parent_id',
    type: 'select'
  }, {
    label: 'Categoria',
    field: 'category_id',
    type: 'select'
  }, {
    label: 'Imagem',
    field: 'image',
    type: 'image',
    props({ props }) {
      const { data } = props
      return {
        preview: data ? data.image_url : null
      }
    }
  }, {
    label: 'Título',
    field: 'title',
    type: 'text'
  }, {
    label: 'Resumo',
    field: 'summary',
    type: 'textarea'
  }, {
    label: 'Data',
    field: 'date',
    type: 'date',
    props () {
      return {
        max: date.getDate()
      }
    }
  }, {
    label: 'Texto',
    field: 'text',
    type: 'texteditor',
    props () {
      return {
        enableFullscreen: true
      }
    }
  }, {
    label: 'PDF (apenas para categoria: X)',
    field: 'pdf',
    type: 'file',
    props({ props }) {
      const { data } = props
      return {
        preview: data ? data.pdf_url : null
      }
    }
  }, {
    label: 'Link (apenas para categoria: X)',
    field: 'link',
    type: 'text'
  }, {
    label: 'Texto para exibir na home (apenas para categoria: X)',
    field: 'home_description',
    type: 'text'
  }, {
    label: 'Logo para exibir na home (apenas para categoria: X)',
    field: 'home_logo',
    type: 'image',
    props({ props }) {
      const { data } = props
      return {
        preview: data ? data.home_logo_url : null
      }
    }
  }, {
    label: 'Membro(s) da equipe',
    field: 'members',
    type: 'checkbox'
  }, {
    label: 'Conteúdo de Especialista?',
    field: 'is_expert',
    type: 'switch',
    props({ model }) {
      const checked = model.is_expert
      return { checked }
    }
  }, {
    label: 'Ativo',
    field: 'status',
    type: 'switch',
    props({ model }) {
      const checked = model.status
      return { checked }
    }
  }]

  componentDidMount() {
    const { data } = this.props
    if (data) {
      const model = {
        ...this.state.model,
        ...data,
        date: date.formatDate(data.date, 'YYYY-MM-DD'),
        status: data.status === 'active',
        members: data.members.map(member => {
          return {
            member_id: member.id,
            remove: false
          }
        })
      }
      this.setState({ model })
    }
  }

  render() {
    const { sending } = this.props
    return (
      <form className="form" onSubmit={this._handleSubmit}>
        {this._getFields()}
        <Button
          type='submit'
          size='small'
          spinner={sending}
          disabled={sending}
        >Salvar</Button>
      </form>
    )
  }

  _getFields = () => {
    const { fields } = this
    const { model, errors } = this.state
    return fields
      .filter(this._filterFields)
      .map((item, index) => {
        let value = model[item.field] || ''
        let otherProps = {}
        if (item.props) {
          otherProps = item.props.call(this, { props: this.props, model })
        }
        if (item.field === 'locale_id') {
          otherProps.options = [{
            value: '',
            label: 'Selecione um idioma'
          }].concat(
            this.props.locales.map(item => {
              return {
                value: item.id,
                label: item.name
              }
            })
          )
        }
        if (item.field === 'category_id') {
          otherProps.options = [{
            value: '',
            label: 'Selecione uma categoria'
          }].concat(
            this.props.categories.map(item => {
              return {
                value: item.id,
                label: item.name
              }
            })
          )
        }
        if (item.field === 'members') {
          value = value
            .filter(item => !item.remove)
            .map(item => item.member_id)
          otherProps.multiple = true
          otherProps.options = this.props.members.map(item => {
            return {
              value: item.id,
              label: item.name
            }
          })
        }
        if (item.field === 'parent_id') {
          otherProps.options = [{
            value: '',
            label: 'Selecione um registro'
          }].concat(
            this.props.parents
              .filter(item => {
                if (this.props.data) {
                  return +this.props.data.id !== +item.id
                }
                return true
              })
              .map(item => {
                return {
                  value: item.id,
                  label: item.title
                }
              })
          )
        }
        const classes = `form-control-box form-control-box--${item.field}`
        return (
          <div
            className={classes}
            key={index}
          >
            <FormControl
              {...otherProps}
              type={item.type}
              label={item.label}
              value={value}
              onChange={this._onChange(item.field)}
              errors={form.getErrors(item.field, errors)}
            />
          </div>
        )
      })
  }

  _handleSubmit = event => {
    event.preventDefault()
    const data = {
      ...this.state.model,
      status: this.state.model.status ? 'active' : 'disabled'
    }
    const method = !this.props.data ? 'create' : 'edit'
    contentValidator[method](data).then(result => {
      if (result.success) {
        this.props.onSubmit(data)
      } else {
        this.setState({ errors: result.errors })
      }
    }).catch(error => {
      this.props.notificate(error.message)
    })
  }

  _onChange = field => {
    return value => {
      const numbers = ['locale_id', 'category_id']
      if (numbers.includes(field) && value) {
        value = +value
      }
      if (field === 'members') {
        const ids = this.state.model.members.map(item => {
          return +item.member_id
        })
        const members = this.state.model.members.map(item => {
          if (+item.member_id === +value) {
            item.remove = !item.remove
          }
          return {
            ...item
          }
        })
        if (!ids.includes(+value)) {
          members.push({
            member_id: +value,
            remove: false
          })
        }
        value = members
      }
      const model = {
        ...this.state.model,
        [field]: value
      }
      if (
        field === 'locale_id' &&
        localeUtils.isMain(this.props.locales, value)
      ) {
        model.parent_id = null
      }
      this.setState({ model }, () => {
        if (field === 'locale_id') {
          this._onChangeLocale()
        }
      })
    }
  }

  _onChangeLocale = () => {
    this.props.onChangeLocale(this.state.model.locale_id)
  }

  _filterFields = field => {
    if (field.field === 'parent_id') {
      const isMain = localeUtils.isMain(
        this.props.locales,
        this.state.model.locale_id
      )
      return !isMain
    }
    return true
  }
}
