import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from '../atoms'
import { Spinner, ImagePreview } from '../molecules'
import { DataTable, Confirmation, ListFilters, FundUploadSpreadsheet } from '../organisms'
import {
  loadFunds,
  destroyFund
} from '../../store/actions/fund'
import { navigate, links, text, humanize, uniqueID, formatSize } from '../../utils'
import { createUpload } from '../../store/actions/upload'
import { updateSetting } from '../../store/actions/setting'
import { notificate } from '../../store/actions/notification'

class Funds extends Component {
  state = {
    filters: {
      page: 1,
      limit: 10,
      search: '',
      locale_id: ''
    },
    currentId: null,
    uploadingFund: false,
  }

  componentDidMount() {
    this._loadFilters(this._loadData)
  }

  render() {
    const { list, paging, sending } = this.props.fund
    const { currentId, uploadingFund } = this.state
    const columns = this._getColumns()
    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          <Link button size='small' to={links.get('createFund')}>
            + Criar Fundo de Investimento
          </Link>
          <FundUploadSpreadsheet
            onChange={this._onUpload}
            loading={uploadingFund}
          />
        </div>
        <ListFilters
          onChange={this._onFilter}
          data={this.props.fund.filters}
          filters={['search', 'locale_id']}
        />
        {sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        <DataTable
          loading={sending}
          columns={columns}
          rows={list}
          paging={paging}
          onEdit={this._onEdit}
          onDelete={this._onDelete}
          onChangePage={this._onChangePage}
        />
      </div>
    )
  }

  _loadData = () => {
    const { token } = this.props.user.user
    const { filters } = this.state
    this.props.loadFunds(token, filters)
  }

  _getColumns = () => {
    return [{
      name: 'ID',
      field: 'id'
    }, {
      name: 'Imagem',
      field: 'image_thumb_url',
      filter: (value, row) =>
        <ImagePreview
          thumbnail={value}
          image={row.image_url}
          width="50"
          className="thumbnail"
          alt={row.title}
        />
    }, {
      name: 'Nome do Fundo de Investimento',
      field: 'name'
    }, {
      name: 'Ordem',
      field: 'sort'
    }, {
      name: 'Idioma',
      field: 'locale',
      filter: value => value ? value.name : null
    }, {
      name: 'Ativo',
      field: 'status',
      filter: value => text.yesNo(value === 'active')
    }]
  }

  _onEdit = item => {
    navigate.to({
      screen: 'editFund',
      props: this.props,
      params: { id: item.id }
    })
  }

  _onDelete = item => {
    this.setState({ currentId: item.id })
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = () => {
    const { token } = this.props.user.user
    const { currentId } = this.state
    this.setState({ currentId: null }, () => {
      this.props.destroyFund(token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onFilter = values => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    values.forEach(item => {
      filters[item.filter] = item.value
    })
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _loadFilters = callback => {
    if (this.props.fund.filters) {
      const filters = {
        ...this.state.filters,
        ...this.props.fund.filters
      }
      this.setState({ filters }, callback)
    } else {
      callback()
    }
  }

  _onUpload = file => {
    this.setState({ uploadingFund: true })
    this._getBase64(file).then(async base64 => {
      let success = false
      const { token } = this.props.user.user
      const parts = file.name.split('.')
      const fileType = parts.pop()
      const name = humanize(parts.join('.'))
      const item = {
        name,
        code: uniqueID(),
        file: base64.result,
        file_type: fileType,
        size: formatSize(file.size),
        status: true
      }
      const result = await this.props.createUpload(token, item)
      if (result.success) {
        const { file_url: fileURL } = result.data
        const name = fileURL.split('/').pop()
        const settingResult = await this.props.updateSetting(token, {
          name: 'upload_fund',
          value: name,
          public: false,
        })
        success = settingResult.success
      }
      if (success) {
        this.props.notificate('Planilha atualizada com sucesso!', 'success')
      } else {
        this.props.notificate('Erro ao atualizar planilha', 'error')
      }
      this.setState({ uploadingFund: false })
    }).catch(error => {
      this.props.notificate(error.message, 'error')
      this.setState({ uploadingFund: false })
    })
  }

  _getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener('load', e => {
        resolve({
          result: e.target.result,
          error: e.target.error
        })
      })
      reader.readAsDataURL(file)
    })
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    fund: state.fund
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadFunds: (token, filters) => dispatch(loadFunds(token, filters)),
    destroyFund: (token, id) => dispatch(destroyFund(token, id)),
    createUpload: (token, data) => dispatch(createUpload(token, data, false)),
    updateSetting: (token, data) => dispatch(updateSetting(token, data, false)),
    notificate: (message, type) => dispatch(notificate(message, type)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Funds)
