export default {
  isMain (locales, id) {
    const locale = locales.find(item => +item.id === +id)
    if (locale) {
      return locale.is_main
    }
    return false
  },
  getMainID (locales)  {
    const locale = locales.find(item => item.is_main)
    return locale ? locale.id : null
  }
}
