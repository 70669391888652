import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '../atoms'
import { FormControl } from '../molecules'
import { sendMail, exportEmails } from '../../store/actions/mail'
import { notificate } from '../../store/actions/notification'
import { emailValidator } from '../../validators'
import { form } from '../../utils'

class Emails extends Component {
  state = {
    errors: {},
    model: {
      subject: ''
    }
  }

  render () {
    const { sending } = this.props.mail
    const { model, errors } = this.state
    return (
      <div>
        <div className="row row--two">
          <div>
            <h1 className="page-title page-title--bold">Enviar informativo</h1>
            <form onSubmit={this._handleClick} action="#">
              <FormControl
                value={model.subject}
                label="Assunto"
                name="subject"
                errors={form.getErrors('subject', errors)}
                onChange={value => this._onChange('subject', value)}
              />
              <Button
                type="submit"
                disabled={sending}
                spinner={sending}
              >Enviar</Button>
            </form>
          </div>
          <div>
            <Button
              onClick={this._exportEmails}
              disabled={sending}
              spinner={sending}
            >Exportar emails</Button>
          </div>
        </div>
      </div>
    )
  }

  _onChange = (name, value) => {
    const model = {
      ...this.state.model,
      [name]: value
    }
    this.setState({ model })
  }

  _handleClick = event => {
    event.preventDefault()
    this.setState({ errors: {} }, () => {
      const data = { ...this.state.model }
      emailValidator.sendMail(data).then(result => {
        if (result.success) {
          const { token } = this.props.user.user
          this.props.sendMail(token, data).then(result => {
            if (result) {
              this.props.notificate('O informativo será enviado em breve!', 'success')
              const model = {
                ...this.state.model,
                subject: ''
              }
              this.setState({ model })
            }
          })
        } else {
          this.setState({ errors: result.errors })
        }
      }).catch(error => {
        this.props.notificate(error.message)
      })
    })
  }

  _exportEmails = () => {
    const { token } = this.props.user.user
    this.props.exportEmails(token).then(result => {
      if (result) {
        let csvContent = 'data:text/csv;charset=utf-8,'
        csvContent += this.props.mail.exportEmails
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'emails.csv')
        link.className = 'btn'
        document.body.appendChild(link)
        link.click()
      }
    })
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    mail: state.mail
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendMail: (token, data) => dispatch(sendMail(token, data)),
    exportEmails: token => dispatch(exportEmails(token)),
    notificate: (message, type = 'error') => dispatch(notificate(message, type))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Emails)
