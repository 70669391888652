import v8n from 'v8n'
import validatorUtils from './validation_utils'

const identifier = 'platforms'

export default {
  create(data) {
    return v8n()
      .schema({
        logo: v8n().not.null().string().minLength(2),
        name: v8n().not.null().string().minLength(2).maxLength(255),
        link: v8n().not.null().string().minLength(2).maxLength(255),
        sort: v8n().not.null().number().greaterThanOrEqual(1),
        status: v8n().not.null().string().minLength(2).maxLength(255)
      })
      .testAsync(data)
      .then(result => ({ success: true }))
      .catch(e => validatorUtils.buildErrors(identifier, e))
  },

  edit(data) {
    return v8n()
      .schema({
        name: v8n().not.null().string().minLength(2).maxLength(255),
        link: v8n().not.null().string().minLength(2).maxLength(255),
        sort: v8n().not.null().number().greaterThanOrEqual(1),
        status: v8n().not.null().string().minLength(2).maxLength(255)
      })
      .testAsync(data)
      .then(result => ({ success: true }))
      .catch(e => validatorUtils.buildErrors(identifier, e))
  }
}
