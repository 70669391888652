import { Component } from 'react'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/user'

class Logout extends Component {
  componentDidMount () {
    const { user } = this.props
    if (user) {
      this.props.logout(user.token)
    }
  }

  render () {
    return false
  }
}

const mapStateToProps = state => {
  return {
    ...state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: token => dispatch(logout(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
