import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '../atoms'
import { FormControl } from '../molecules'
import { mailTest } from '../../store/actions/mail'

class MailTest extends Component {
  state = {
    buttonText: 'Visualizar e-mail',
    model: {
      send_mail: false
    }
  }

  render () {
    const { sending, mailTest } = this.props.mail
    const { model } = this.state
    return (
      <div>
        <h1 className="page-title page-title--bold">Testar e-mail</h1>
        <div className="row">
          <div className="col">
            <FormControl
              name="email"
              type="checkbox"
              label="Enviar e-mail também"
              value={model.send_mail}
              onChange={value => this._onChange('send_mail', value)}
            />
          </div>
          <div className="col">
            <Button
              disabled={sending}
              onClick={this._submit}
            >{this.state.buttonText}</Button>
          </div>
        </div>
        <div className="mail-test-preview">
          <div dangerouslySetInnerHTML={{ __html: mailTest }}></div>
        </div>
      </div>
    )
  }

  _submit = () => {
    const { token } = this.props.user.user
    this.props.mailTest(token, {
      ...this.state.model
    })
  }

  _onChange = (field, value) => {
    const model = {
      ...this.state.model,
      [field]: value
    }
    let buttonText = this.state.buttonText
    if (field === 'send_mail') {
      if (value) {
        buttonText = 'Enviar e visualizar e-mail'
      } else {
        buttonText = 'Visualizar e-mail'
      }
    }
    this.setState({ model, buttonText })
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    mail: state.mail
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    mailTest: (token, params) => dispatch(mailTest(token, params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MailTest)
