import React, { Component } from 'react'
import RichTextEditor, { Button as RichButton } from 'react-rte'
import { Button, Icon } from '../atoms'
import FormControl from './FormControl'

class TextEditor extends Component {
  static defaultProps = {
    value: '',
    onChange: () => { }
  }

  state = {
    textEditorValue: RichTextEditor.createEmptyValue(),
    textEditorValueSetted: false,
    popover: null,
    youtube: {
      url: '',
      width: '',
      height: ''
    }
  }

  componentDidUpdate (prevProps) {
    if (!this.state.textEditorValueSetted) {
      this.setState({
        textEditorValueSetted: true,
        textEditorValue: RichTextEditor.createValueFromString(
          this.props.value,
          'html'
        )
      })
    }
  }

  render () {
    return (
      <div className="text-editor">
        <RichTextEditor
          ref={ref => this.textEditor = ref}
          className="form__text-editor"
          value={this.state.textEditorValue}
          toolbarConfig={this._getToolbarConfig()}
          customControls={this._getCustomControls()}
          onChange={value => {
            this.setState({
              textEditorValue: value
            }, () => {
              this.props.onChange(value.toString('html'))
            })
          }}
        />
      </div>
    )
  }

  _getToolbarConfig = () => {
    return {
      display: [
        'INLINE_STYLE_BUTTONS',
        'BLOCK_TYPE_BUTTONS',
        'IMAGE_BUTTON',
        'LINK_BUTTONS',
        'BLOCK_TYPE_DROPDOWN',
        'HISTORY_BUTTONS'
      ],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH' },
        { label: 'Monospace', style: 'CODE' },
        { label: 'Underline', style: 'UNDERLINE' }
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: 'Parágrafo', style: 'unstyled' },
        { label: 'Título', style: 'header-one' },
        { label: 'Subtítulo 1', style: 'header-two' },
        { label: 'Subtítulo 2', style: 'header-three' },
        { label: 'Subtítulo 3', style: 'header-four' },
        { label: 'Code Block', style: 'code-block' }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
        { label: 'Blockquote', style: 'blockquote' }
      ]
    }
  }

  _getCustomControls = () => {
    return [
      this._youtubeButton
    ]
  }

  _youtubeButton = () => {
    const { popover, youtube } = this.state
    const classes = ['text-editor__button-popover']
    if (popover === 'youtube') {
      classes.push('text-editor__button-popover--enabled')
    }
    return (
      <div className={classes.join(' ')} key="youtube-button">
        <RichButton
          className="text-editor__button-popover__button"
          onClick={() => this.setState({ popover: !popover ? 'youtube' : null })}
        >
          <Icon name="youtube" />
        </RichButton>
        <div className="text-editor__button-popover__popover">
          <div className="text-editor__button-popover__popover__wrapper">
            <div className="text-editor__button-popover__popover__wrapper__input">
              <FormControl
                value={youtube.url}
                label="URL do Youtube"
                onChange={value => {
                  const youtube = {
                    ...this.state.youtube,
                    url: value
                  }
                  this.setState({ youtube })
                }}
              />
              <FormControl
                value={youtube.width}
                label="Largura"
                onChange={value => {
                  const youtube = {
                    ...this.state.youtube,
                    width: value
                  }
                  this.setState({ youtube })
                }}
              />
              <FormControl
                value={youtube.height}
                label="Altura"
                onChange={value => {
                  const youtube = {
                    ...this.state.youtube,
                    height: value
                  }
                  this.setState({ youtube })
                }}
              />
            </div>
            <div className="text-editor__button-popover__popover__wrapper__buttons">
              <Button
                type="button"
                size="small"
                onClick={() => {
                  this._addYoutube({
                    ...this.state.youtube
                  })
                  this.setState({ popover: null })
                }}
              >Adicionar</Button>
              <Button
                type="button"
                size="small"
                onClick={() => {
                  this.setState({ popover: null })
                }}
              >Cancelar</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _addYoutube = data => {
    let params = [`url="${data.url}"`]
    if (data.width) {
      params.push(`width="${data.width}"`)
    }
    if (data.height) {
      params.push(`height="${data.height}"`)
    }
    params = params.join(' ')
    const cd = new DataTransfer()
    cd.setData('text/plain', `[youtube ${params}]`)
    this.textEditor.refs.editor._onPaste({
      preventDefault : () => {},
      clipboardData: cd
    })
    this.textEditor.refs.editor._onFocus()
    this.setState({
      youtube: {
        url: '',
        width: '',
        height: ''
      }
    })
  }
}

export default TextEditor
