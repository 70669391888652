import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '../molecules'
import { Icon } from '../atoms'
import { form, humanize, uniqueID, formatSize } from '../../utils'

export default class UploadForm extends Component {
  static propTypes = {
    sending: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    notificate: PropTypes.func.isRequired
  }

  state = {
    model: {
      files: []
    },
    errors: {}
  }

  fields = [{
    field: 'file',
    type: 'dropzone',
    props ({ model, props }) {
      return {
        onSelectFile: this._onSelectFile
      }
    }
  }]

  render() {
    const { sending } = this.props
    const queueFilesClasses = ['queue-files']
    if (sending) {
      queueFilesClasses.push('queue-files--sending')
    }
    return (
      <form className="form">
        {this._getFields()}
        <div className={queueFilesClasses.join(' ')}>
          {this._getQueueFiles()}
        </div>
      </form>
    )
  }

  cleanData () {
    const model = {
      files: []
    }
    this.setState({ model })
  }

  _getFields = () => {
    const { fields } = this
    const { model, errors } = this.state
    return fields.map((item, index) => {
      const value = model[item.field] || ''
      let otherProps = {}
      if (item.props) {
        otherProps = item.props.call(this, { props: this.props, model })
      }
      return (
        <FormControl
          {...otherProps}
          key={index}
          type={item.type}
          label={item.label}
          value={value}
          errors={form.getErrors(item.field, errors)}
        />
      )
    })
  }

  _handleSubmit = event => {
    if (event) event.preventDefault()
    const { files } = this.state.model
    if (files.length > 0) {
      this.props.onSubmit(files)
    }
  }

  _onSelectFile = files => {
    const model = {
      ...this.state.model,
      files: [
        ...this.state.model.files,
        ...files.map(file => {
          const parts = file.file.name.split('.')
          const fileType = parts.pop()
          const name = humanize(parts.join('.'))
          return {
            name,
            code: uniqueID(),
            file: file.base64,
            file_type: fileType,
            size: formatSize(file.file.size),
            status: true
          }
        })
      ]
    }
    this.setState({ model }, () => {
      this._handleSubmit()
    })
  }

  _getQueueFiles = () => {
    const { files } = this.state.model
    return files.map((file) => {
      return (
        <div className="queue-file" key={file.code}>
          <div className="queue-file__name">
            {file.name}
          </div>
          <div className="queue-file__size">
            {file.size}
          </div>
          <div
            className="queue-file__remove"
            onClick={() => this._removeQueueFile(file.code)}
          >
            <Icon name="close" />
          </div>
        </div>
      )
    })
  }

  _removeQueueFile = code => {
    const files = this.state.model.files.filter(item => {
      return item.code !== code
    })
    this.setState({
      model: {
        ...this.state.model,
        files
      }
    })
  }
}
