import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from '../atoms'
import { Spinner, ImagePreview } from '../molecules'
import { DataTable, Confirmation, ListFilters } from '../organisms'
import {
  loadContents,
  destroyContent
} from '../../store/actions/content'
import { navigate, links, text, date } from '../../utils'

class Contents extends Component {
  state = {
    filters: {
      page: 1,
      limit: 10,
      search: '',
      locale_id: ''
    },
    currentId: null
  }

  componentDidMount() {
    this._loadFilters(this._loadData)
  }

  render() {
    const { list, paging, sending } = this.props.content
    const { currentId } = this.state
    const columns = this._getColumns()
    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          <Link button size='small' to={links.get('createContent')}>
            + Criar Conteúdo
          </Link>
        </div>
        <ListFilters
          onChange={this._onFilter}
          data={this.props.content.filters}
          filters={['search', 'locale_id', 'category_id']}
        />
        {sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        <DataTable
          loading={sending}
          columns={columns}
          rows={list}
          paging={paging}
          onEdit={this._onEdit}
          onDelete={this._onDelete}
          onChangePage={this._onChangePage}
        />
      </div>
    )
  }

  _loadData = () => {
    const { token } = this.props.user.user
    const { filters } = this.state
    this.props.loadContents(token, filters)
  }

  _getColumns = () => {
    return [{
      name: 'ID',
      field: 'id'
    }, {
      name: 'Imagem',
      field: 'image_thumb_url',
      filter: (value, row) => {
        if (value) {
          return (
            <ImagePreview
              thumbnail={value}
              image={row.image_url}
              width="50"
              className="thumbnail"
              alt={row.title}
            />
          )
        }
      }
    }, {
      name: 'Categoria',
      field: 'category',
      filter: value => value ? value.name : null
    }, {
      name: 'Título',
      field: 'title'
    }, {
      name: 'Data',
      field: 'date',
      filter: value => value ? date.formatDate(value) : null
    }, {
      name: 'Idioma',
      field: 'locale',
      filter: value => value ? value.name : null
    }, {
      name: 'Ativo',
      field: 'status',
      filter: value => text.yesNo(value === 'active')
    }]
  }

  _onEdit = item => {
    navigate.to({
      screen: 'editContent',
      props: this.props,
      params: { id: item.id }
    })
  }

  _onDelete = item => {
    this.setState({ currentId: item.id })
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = () => {
    const { token } = this.props.user.user
    const { currentId } = this.state
    this.setState({ currentId: null }, () => {
      this.props.destroyContent(token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onFilter = values => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    values.forEach(item => {
      filters[item.filter] = item.value
    })
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _loadFilters = callback => {
    if (this.props.content.filters) {
      const filters = {
        ...this.state.filters,
        ...this.props.content.filters
      }
      this.setState({ filters }, callback)
    } else {
      callback()
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    content: state.content
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadContents: (token, filters) => dispatch(loadContents(token, filters)),
    destroyContent: (token, id) => dispatch(destroyContent(token, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contents)
