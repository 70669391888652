import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  loadBanners,
  loadBanner,
  updateBanner
} from '../../store/actions/banner'
import { notificate } from '../../store/actions/notification'
import { loadLocales } from '../../store/actions/locale'
import { BackToList, FormTips, Spinner } from '../molecules'
import { BannerForm } from '../organisms'
import { localeUtils } from '../../utils'

class EditBanner extends Component {
  constructor(props) {
    super(props)
    const { token } = props.user.user
    const { id } = props.match.params
    props.loadBanner(token, id)
    props.loadLocales(token).then(() => {
      props.loadBanners(token, {
        options: true,
        locale_id: localeUtils.getMainID(this.props.locale.list)
      })
    })
  }

  render() {
    const { data, sending } = this.props.banner
    return (
      <div>
        <BackToList to='banners' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Editar Banner</h1>
            {!data && sending &&
              <Spinner />
            }
            {data &&
              <BannerForm
                data={data}
                sending={sending}
                onSubmit={this._handleSubmit}
                notificate={this._onNotificate}
                locales={this.props.locale.list}
                parents={this.props.banner.list}
              />
            }
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='banner' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.user.user
    this.props.updateBanner(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    banner: state.banner,
    locale: state.locale
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadBanners: (token, filters = {}) => dispatch(loadBanners(token, filters)),
    loadBanner: (token, id) => dispatch(loadBanner(token, id)),
    updateBanner: (token, data) => dispatch(updateBanner(token, data)),
    loadLocales: token => dispatch(loadLocales(token)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBanner)
