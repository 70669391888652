import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Spinner } from '../molecules'
import { SettingItem } from '../organisms'
import {
  loadSettings,
  updateSetting
} from '../../store/actions/setting'

class Settings extends Component {
  state = {
    filterSetting: null,
    loaded: false
  }

  componentDidMount () {
    this._loadData()
  }

  render () {
    const { sending, list } = this.props.setting
    const { loaded } = this.state
    return (
      <div>
        <h1 className="page-title page-title--bold">Configurações</h1>
        <p>Após alterar a configuração desejada, basta apertar <code className="code">Enter</code> ou dar desfoque no campo.</p>
        <br />
        {sending && list.length === 0 &&
          <div className="center">
            <Spinner />
          </div>
        }
        {loaded &&
          <div>
            {this._getGroupFilters()}
            <br/>
            <br/>
            <div className="row row--two" style={{ marginBottom: 20 }}>
              <strong>Português</strong>
              <strong>English</strong>
            </div>
            {this._getItems()}
          </div>
        }
      </div>
    )
  }

  _getItems = () => {
    const { sending, list, currentID } = this.props.setting
    const buildSettingItem = ({ item, sending }) => {
      return (
        <SettingItem
          id={item.id}
          description={item.description}
          name={item.name}
          value={item.value}
          isPublic={item.public}
          localeId={item.locale_id}
          onChange={this._updateSetting}
          sending={sending}
        />
      )
    }
    const findLanguageItem = (item, localeId) => {
      let setting = list.find(setting => {
        return (
          setting.name === item.name &&
          +setting.locale_id === +localeId
        )
      })
      if (!setting) {
        setting = {
          ...item,
          id: null,
          value: '',
          locale_id: localeId
        }
      }
      return setting
    }

    return list
      .filter(this._filterItems)
      .filter(item => {
        return (
          item.locale_id === 1 ||
          item.locale_id === null
        )
      })
      .map(item => {
        const itemEnglish = findLanguageItem(item, 2)
        const sendingItem = (
          sending &&
          item.id === currentID
        )
        const sendingItemEnglish = (
          sending &&
          itemEnglish.id === currentID &&
          itemEnglish.id !== null
        )
        return (
          <div
            key={item.id}
            className="row row--two"
          >
            {buildSettingItem({ item, sending: sendingItem })}
            {buildSettingItem({ item: itemEnglish, sending: sendingItemEnglish })}
          </div>
        )
      })
  }

  _loadData = async () => {
    const { token } = this.props.user.user
    await this.props.loadSettings(token)
    const groups = this._getGroups()
    const filterSetting = groups.shift()
    this.setState({ filterSetting, loaded: true })
  }

  _updateSetting = data => {
    const { token } = this.props.user.user
    this.props.updateSetting(token, data)
  }

  _filterItems = item => {
    if (this.state.filterSetting) {
      return item.description.startsWith(this.state.filterSetting)
    }
    return true
  }

  _getGroups = () => {
    const { list } = this.props.setting
    return list
      .map(item => item.description)
      .map(value => value.split('-').shift().trim())
      .filter((value, index, self) => self.indexOf(value) === index)
  }

  _getGroupFilters = () => {
    const groups = this._getGroups()
    return groups.map(item => {
      const enabled = item === this.state.filterSetting
      const classes = ['button', 'button--small']
      if (enabled) {
        classes.push('button--disabled')
      }
      const onClick = () => {
        this.setState({ filterSetting: item })
      }
      return (
        <span
          key={item}
          className={classes.join(' ')}
          onClick={onClick}
        >
          {item}
        </span>
      )
    })
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    setting: state.setting
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadSettings: token => dispatch(loadSettings(token)),
    updateSetting: (token, data) => dispatch(updateSetting(token, data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)

