export default function ({ name, type, data }) {
  const blob = new Blob([data])
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, name)
  } else {
    const a = window.document.createElement('a')
    a.href = window.URL.createObjectURL(blob, { type })
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
