import * as pages from '../components/pages'

export default {
  routes: [{
    name: 'dashboard',
    path: '/',
    component: pages.Dashboard,
    requiresAuth: true
  }, {
    name: 'mailTest',
    path: '/testar-email',
    component: pages.MailTest,
    requiresAuth: true
  }, {
    name: 'emails',
    path: '/emails',
    component: pages.Emails,
    requiresAuth: true
  }, {
    name: 'settings',
    path: '/configuracoes',
    component: pages.Settings,
    requiresAuth: true
  }, {
    name: 'uploads',
    path: '/uploads',
    component: pages.Uploads,
    requiresAuth: true
  }, {
    name: 'contents',
    path: '/conteudo',
    component: pages.Contents,
    requiresAuth: true
  }, {
    name: 'createContent',
    path: '/conteudo/adicionar',
    component: pages.CreateContent,
    requiresAuth: true,
    parent: 'contents'
  }, {
    name: 'editContent',
    path: '/conteudo/editar/:id',
    component: pages.EditContent,
    requiresAuth: true,
    parent: 'contents'
  }, {
    name: 'banners',
    path: '/banners',
    component: pages.Banners,
    requiresAuth: true
  }, {
    name: 'createBanner',
    path: '/banners/adicionar',
    component: pages.CreateBanner,
    requiresAuth: true,
    parent: 'banners'
  }, {
    name: 'editBanner',
    path: '/banners/editar/:id',
    component: pages.EditBanner,
    requiresAuth: true,
    parent: 'banners'
  }, {
    name: 'files',
    path: '/manuais-e-documentos',
    component: pages.Files,
    requiresAuth: true
  }, {
    name: 'createFile',
    path: '/manuais-e-documentos/adicionar',
    component: pages.CreateFile,
    requiresAuth: true,
    parent: 'files'
  }, {
    name: 'editFile',
    path: '/manuais-e-documentos/editar/:id',
    component: pages.EditFile,
    requiresAuth: true,
    parent: 'files'
  }, {
    name: 'platforms',
    path: '/plataformas',
    component: pages.Platforms,
    requiresAuth: true
  }, {
    name: 'createPlatform',
    path: '/plataformas/adicionar',
    component: pages.CreatePlatform,
    requiresAuth: true,
    parent: 'platforms'
  }, {
    name: 'editPlatform',
    path: '/plataformas/editar/:id',
    component: pages.EditPlatform,
    requiresAuth: true,
    parent: 'platforms'
  }, {
    name: 'members',
    path: '/equipe',
    component: pages.Members,
    requiresAuth: true
  }, {
    name: 'createMember',
    path: '/equipe/adicionar',
    component: pages.CreateMember,
    requiresAuth: true,
    parent: 'members'
  }, {
    name: 'editMember',
    path: '/equipe/editar/:id',
    component: pages.EditMember,
    requiresAuth: true,
    parent: 'members'
  }, {
    name: 'categories',
    path: '/categorias',
    component: pages.Categories,
    requiresAuth: true
  }, {
    name: 'createCategory',
    path: '/categorias/adicionar',
    component: pages.CreateCategory,
    requiresAuth: true,
    parent: 'categories'
  }, {
    name: 'editCategory',
    path: '/categorias/editar/:id',
    component: pages.EditCategory,
    requiresAuth: true,
    parent: 'categories'
  }, {
    name: 'users',
    path: '/usuarios',
    component: pages.Users,
    requiresAuth: true
  }, {
    name: 'createUser',
    path: '/usuarios/adicionar',
    component: pages.CreateUser,
    requiresAuth: true,
    parent: 'users'
  }, {
    name: 'editUser',
    path: '/usuarios/editar/:id',
    component: pages.EditUser,
    requiresAuth: true,
    parent: 'users'
  }, {
    name: 'funds',
    path: '/fundos',
    component: pages.Funds,
    requiresAuth: true
  }, {
    name: 'createFund',
    path: '/fundos/adicionar',
    component: pages.CreateFund,
    requiresAuth: true,
    parent: 'funds'
  }, {
    name: 'editFund',
    path: '/fundos/editar/:id',
    component: pages.EditFund,
    requiresAuth: true,
    parent: 'funds'
  }, {
    name: 'contacts',
    path: '/contatos',
    component: pages.Contacts,
    requiresAuth: true
  }, {
    name: 'login',
    path: '/login',
    component: pages.Login
  }, {
    name: 'logout',
    path: '/logout',
    component: pages.Logout,
    requiresAuth: true
  }]
}
