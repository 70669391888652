import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Spinner, ModuleCount } from '../molecules'
import { loadDashboard } from '../../store/actions/page'

class Dashboard extends Component {
  componentDidMount () {
    const { token } = this.props.user.user
    this.props.loadDashboard(token)
  }

  render () {
    const { sending } = this.props.page
    return (
      <div>
        <h1 className="page-title page-title--bold">Resumo</h1>
        {sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        <div className="dashboard-items">
          {this._getItems()}
        </div>
      </div>
    )
  }

  _getItems = () => {
    const { dashboard, sending } = this.props.page
    if (sending || !dashboard || !dashboard.counter) {
      return false
    }
    return dashboard.counter.map((item, index) => {
      return (
        <ModuleCount
          key={index}
          item={item}
        />
      )
    })
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    page: state.page
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadDashboard: token => dispatch(loadDashboard(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
