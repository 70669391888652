import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createBanner, loadBanners } from '../../store/actions/banner'
import { notificate } from '../../store/actions/notification'
import { loadLocales } from '../../store/actions/locale'
import { BackToList, FormTips } from '../molecules'
import { BannerForm } from '../organisms'
import { localeUtils } from '../../utils'

class CreateBanner extends Component {
  constructor(props) {
    super(props)
    const { token } = props.user.user
    props.loadLocales(token).then(() => {
      props.loadBanners(token, {
        options: true,
        locale_id: localeUtils.getMainID(this.props.locale.list)
      })
    })
  }

  render() {
    const { sending } = this.props.banner
    return (
      <div>
        <BackToList to='banners' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de novo Banner</h1>
            <BannerForm
              sending={sending}
              onSubmit={this._handleSubmit}
              notificate={this._onNotificate}
              locales={this.props.locale.list}
              parents={this.props.banner.list}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='banner' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.user.user
    this.props.createBanner(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    banner: state.banner,
    locale: state.locale
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadBanners: (token, filters = {}) => dispatch(loadBanners(token, filters)),
    createBanner: (token, data) => dispatch(createBanner(token, data)),
    loadLocales: token => dispatch(loadLocales(token)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBanner)
