const initialState = {
  data: null,
  list: [],
  paging: null,
  sending: false
}

const identifier = 'contact'

export default (state = initialState, action) => {
  let nextState = { ...state }

  switch (action.type) {
    case 'BEFORE_SEND':
      if (action.identifier === identifier) {
        nextState.sending = true
      }
      break

    case 'AFTER_RESPONSE':
      if (action.identifier === identifier) {
        nextState.sending = false
      }
      break

    case 'SET_CONTACTS':
      nextState = {
        ...state,
        list: action.data.data,
        paging: action.data.paging
      }
      break

    case 'SET_CONTACT':
      nextState = {
        ...state,
        data: action.data
      }
      break

    default:
      nextState = state
      break
  }

  return nextState
}
