import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from '../atoms'
import { Spinner } from '../molecules'
import { DataTable, Confirmation } from '../organisms'
import {
  loadUsers,
  destroyUser
} from '../../store/actions/user'
import { navigate, links, text } from '../../utils'

class Users extends Component {
  state = {
    filters: {
      page: 1,
      limit: 10
    },
    currentId: null
  }

  componentDidMount() {
    this._loadData()
  }

  render() {
    const { list, paging, sending } = this.props.user
    const { currentId } = this.state
    const columns = this._getColumns()
    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          <Link button size='small' to={links.get('createUser')}>
            + Cadastrar Usuário
          </Link>
        </div>
        {sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        <DataTable
          loading={sending}
          columns={columns}
          rows={this._formatList(list)}
          paging={paging}
          onEdit={this._onEdit}
          onDelete={this._onDelete}
          onChangePage={this._onChangePage}
        />
      </div>
    )
  }

  _loadData = () => {
    const { token } = this.props.user.user
    const { filters } = this.state
    this.props.loadUsers(token, filters)
  }

  _getColumns = () => {
    return [{
      name: 'ID',
      field: 'id'
    }, {
      name: 'Nome',
      field: 'name'
    }, {
      name: 'E-mail',
      field: 'email'
    }, {
      name: 'Ativo',
      field: 'status',
      filter: value => text.yesNo(value === 'active')
    }]
  }

  _onEdit = item => {
    navigate.to({
      screen: 'editUser',
      props: this.props,
      params: { id: item.id }
    })
  }

  _onDelete = item => {
    this.setState({ currentId: item.id })
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = () => {
    const { token } = this.props.user.user
    const { currentId } = this.state
    this.setState({ currentId: null }, () => {
      this.props.destroyUser(token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _formatList = list => {
    return list.map(item => {
      return {
        ...item,
        statusFormated: (
          item.status === 'active'
            ? 'Sim'
            : 'Não'
        )
      }
    })
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: (token, filters) => dispatch(loadUsers(token, filters)),
    destroyUser: (token, id) => dispatch(destroyUser(token, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
