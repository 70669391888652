import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class FormTips extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired
  }

  render() {
    const content = this._getContent()
    if (!content) {
      return false
    }
    return (
      <div className="form-tips">
        <h2 className="page-title">Recomendações</h2>
        <div className="form-tips__content">
          {content}
        </div>
      </div>
    )
  }

  _getContent = () => {
    const { name } = this.props
    const items = {
      modality: [
        'Campo nome limitar até 40 caracteres;',
        'Campo Descrição limitado a 100 caracteres;',
        'Quantidade de Troféus, númeral para exibir a qualidade de troféus que a modalidade no geral conquistou até o momento;',
        'Imagem: Carregue uma imagem nos formatos .png ou .jpg com peso máximo de 200kb e na resolução de 600x500;',
        'Ao salvar será inserido no banco esse novo item, para excluir vá na listagem e clique em Excluir.',
      ],
      streamer: [
        'Imagem: Carregue uma imagem nos formatos .png ou .jpg com peso máximo de 200kb e na resolução de 600x500;',
      ],
      player: [
        'Imagem: Carregue uma imagem nos formatos .png ou .jpg com peso máximo de 200kb e na resolução de 600x500;',
      ],
      event: [
        'Imagem: Carregue uma imagem nos formatos .png ou .jpg com peso máximo de 200kb e na resolução de 600x500;',
      ],
      room: [
        'Imagem: Carregue uma imagem nos formatos .png ou .jpg com peso máximo de 200kb e na resolução de 600x500;',
      ]
    }
    const item = items[name]
    if (item) {
      return item.map((value, index) => {
        return <p key={index}>{value}</p>
      })
    }
  }
}
