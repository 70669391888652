import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { links } from '../../utils'

export default class HeaderMenu extends Component {
  state = {
    opened: false
  }

  render () {
    const classes = this._getClasses()
    return (
      <div className={classes}>
        <div
          className="header-menu__open-nav"
          onClick={this._toggleNav}
        >
          <span className="header-menu__open-nav__line"></span>
          <span className="header-menu__open-nav__line"></span>
          <span className="header-menu__open-nav__line"></span>
        </div>
        <div className="header-menu__nav">
          <ul className="header-menu__nav__items">
            {this._getItems()}
          </ul>
        </div>
      </div>
    )
  }

  _getClasses = () => {
    let classes = ['header-menu']
    if (this.state.opened) {
      classes.push('header-menu--is-opened')
    }
    return classes.join(' ')
  }

  _toggleNav = () => {
    this.setState({ opened: !this.state.opened })
  }

  _getItems = () => {
    const items = [{
      label: 'Dashboard',
      screen: 'dashboard'
    }, {
      label: 'Testar e-mail',
      screen: 'mailTest'
    }, {
      label: 'Enviar informativo',
      screen: 'emails'
    }, {
      label: 'Fundos de Investimento',
      screen: 'funds'
    }, {
      label: 'Conteúdo',
      screen: 'contents'
    }, {
      label: 'Biblioteca',
      screen: 'uploads'
    }, {
      label: 'Banners',
      screen: 'banners'
    }, {
      label: 'Manuais/Documentos',
      screen: 'files'
    }, {
      label: 'Plataformas',
      screen: 'platforms'
    }, {
      label: 'Equipe',
      screen: 'members'
    }, {
      label: 'Categorias',
      screen: 'categories'
    }, {
      label: 'Contato',
      screen: 'contacts'
    }, {
      label: 'Usuários',
      screen: 'users'
    }, {
      label: 'Configurações',
      screen: 'settings'
    }]
    let currentScreen
    if (this.props.location) {
      currentScreen = links.getReverse(this.props.location.pathname)
    }
    return items.map((item, index) => {
      let classes = ['header-menu__nav__item']
      if (
        currentScreen === item.screen ||
        links.isChild(currentScreen, item.screen)
      ) {
        classes.push('header-menu__nav__item--active')
      }
      return (
        <li
          key={index}
          className={classes.join(' ')}
        >
          <Link
            to={links.get(item.screen)}
            className="header-menu__nav__item__link"
            onClick={this._toggleNav}
          >
            {item.label}
          </Link>
        </li>
      )
    })
  }
}
