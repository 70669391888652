import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import { Icon } from '../atoms'

export default class DataTable extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    paging: PropTypes.object,
    disabled: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onChangePage: PropTypes.func
  }

  static defaultProps = {
    rows: [],
    paging: {
      pages: 0,
      page: 0,
      limit: 10,
      count: 0
    },
    disabled: false
  }

  render() {
    const classes = this._getClasses()
    const { paging } = this.props
    return (
      <div className={classes}>
        <table className="data-table__table">
          <thead>
            <tr>
              {this._getColumns()}
            </tr>
          </thead>
          <tbody>
            {this._getRows()}
          </tbody>
        </table>
        {paging && +paging.pages > 0 &&
          <div className="pagination-and-info">
            <ReactPaginate
              previousLabel={'Anterior'}
              nextLabel={'Próxima'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              initialPage={paging.page - 1}
              pageCount={paging.pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this._handlePageChange}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
            <div className="pagination-info">
              <strong>{paging.pages}</strong> página(s), <strong>{paging.count}</strong> registro(s)
            </div>
          </div>
        }
      </div>
    )
  }

  _getClasses = () => {
    const { loading } = this.props
    let classes = ['data-table']
    if (loading) {
      classes.push('data-table--loading')
    }
    return classes.join(' ')
  }

  _getColumns = () => {
    const { onEdit, onDelete } = this.props
    let columns = [...this.props.columns]
    if (onEdit || onDelete) {
      columns.push({
        name: 'Ações'
      })
    }
    return columns.map((column, columnIndex) => {
      return (
        <th key={columnIndex}>
          {column.name}
        </th>
      )
    })
  }

  _getRows = () => {
    const { rows, columns } = this.props
    return rows.map((row, rowIndex) => {
      return (
        <tr key={rowIndex}>
          {columns.map((column, columnIndex) => {
            let value = row[column.field]
            if (typeof column.filter === 'function') {
              value = column.filter(value, row)
            }
            return (
              <td
                key={columnIndex}
                data-column={column.name}
              >{value}</td>
            )
          })}
          {this._getActions(row)}
        </tr>
      )
    })
  }

  _getActions = row => {
    const { onEdit, onDelete } = this.props
    let actions = []
    if (onEdit) {
      actions.push(this._editButton(row))
    }
    if (onDelete) {
      actions.push(this._deleteButton(row))
    }
    if (actions.length > 0) {
      return (
        <td
          data-column="Ações"
        >{actions}</td>
      )
    }
  }

  _editButton = row => {
    const { onEdit } = this.props
    return (
      <span
        key={1}
        className="data-table__table__actions__button data-table__table__actions__button--edit"
        onClick={() => onEdit(row)}
      >
        <Icon name='pencil' />
      </span>
    )
  }

  _deleteButton = row => {
    const { onDelete } = this.props
    return (
      <span
        key={2}
        className="data-table__table__actions__button data-table__table__actions__button--delete"
        onClick={() => onDelete(row)}
      >
        <Icon name='trash' />
      </span>
    )
  }

  _handlePageChange = data => {
    const newPage = +data.selected + 1
    if (this.props.paging.page !== newPage) {
      this.props.onChangePage(data)
    }
  }
}
