import routes from '../routes'

export default {
  get (name, props = {}) {
    const link = routes.routes.find(item => item.name === name)
    if (link) {
      let { path } = link
      for (const field in props) {
        path = path.replace(`:${field}`, props[field])
      }
      return path
    }
  },
  getReverse (pathname) {
    let normalizedPathname = ''
    if (+pathname.split('/').pop() > 0) {
      const pieces = pathname.split('/')
      normalizedPathname = pieces.map((item, index) => {
        if ((pieces.length - 1) === +index) {
          return ':id'
        }
        return item
      }).join('/')
    }
    const link = routes.routes.find(item => {
      return (
        item.path === pathname ||
        item.path === normalizedPathname
      )
    })
    if (link) {
      return link.name
    }
  },
  isChild (childScreen, parentScreen) {
    const childRoute = routes.routes.find(item => item.name === childScreen)
    if (childRoute && childRoute.parent) {
      return childRoute.parent === parentScreen
    }
    return false
  }
}
