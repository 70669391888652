import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createPlatform } from '../../store/actions/platform'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { PlatformForm } from '../organisms'

class CreatePlatform extends Component {
  render() {
    const { sending } = this.props.platform
    return (
      <div>
        <BackToList to='platforms' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de novo Plataforma</h1>
            <PlatformForm
              sending={sending}
              onSubmit={this._handleSubmit}
              notificate={this._onNotificate}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='platform' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.user.user
    this.props.createPlatform(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    platform: state.platform
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createPlatform: (token, data) => dispatch(createPlatform(token, data)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlatform)
