export default {
  header: {
    controlPanel: 'Painel administrativo'
  },
  titles: {
    login: 'Login'
  },
  user: {
    email: 'E-mail',
    password: 'Senha'
  },
  actions: {
    enter: 'Entrar'
  },
  validations: {
    users: {
      email: {
        validEmail: 'E-mail inválido'
      },
      password: {
        string: 'Digite sua senha',
        minLength: 'Sua senha precisa ter ao menos 6 caracteres'
      },
      name: {
        string: 'Digite o nome',
        minLength: 'Digite o nome'
      }
    },
    emails: {
      subject: {
        minLength: 'Digite o assunto do e-mail'
      }
    },
    uploads: {
      name: {
        maxLength: 'Máximo 255 caracateres'
      },
      file: {
        minLength: 'O arquivo é obrigatório',
        null: 'O arquivo é obrigatório'
      }
    },
    banners: {
      locale_id: {
        null: 'Escolha um idioma',
        number: 'Idioma inválido'
      },
      file: {
        null: 'Arquivo inválido',
        minLength: 'Arquivo inválido'
      },
      file_type: {
        null: 'Tipo do arquivo inválido',
        minLength: 'Tipo do arquivo inválido'
      },
      title: {
        null: 'Digite o título',
        minLength: 'Digite o título'
      },
      description: {
        null: 'Digite a descrição',
        minLength: 'Digite a descrição'
      },
      button_text: {
        null: 'Digite o texto do botão',
        minLength: 'Digite o texto do botão'
      },
      button_link: {
        null: 'Digite o link',
        minLength: 'Digite o link'
      },
      sort: {
        null: 'Ordem inválida',
        number: 'Ordem inválida',
        greaterThanOrEqual: 'Ordem inválida'
      },
      status: {
        null: 'Status inválido',
        minLength: 'Status inválido'
      }
    },
    files: {
      locale_id: {
        null: 'Escolha um idioma',
        number: 'Idioma inválido'
      },
      name: {
        null: 'Digite o nome do manual/documento',
        minLength: 'Digite o nome do manual/documento'
      },
      file: {
        null: 'Arquivo inválido',
        minLength: 'Arquivo inválido'
      },
      file_type: {
        null: 'Tipo do arquivo inválido',
        minLength: 'Tipo do arquivo inválido'
      },
      status: {
        null: 'Status inválido',
        minLength: 'Status inválido'
      }
    },
    platforms: {
      name: {
        null: 'Digite o nome do manual/documento',
        minLength: 'Digite o nome do manual/documento'
      },
      logo: {
        null: 'Logo inválido',
        minLength: 'Logo inválido'
      },
      link: {
        null: 'Link inválido',
        minLength: 'Link inválido'
      },
      sort: {
        null: 'Ordem inválida',
        number: 'Ordem inválida',
        greaterThanOrEqual: 'Ordem inválida'
      },
      status: {
        null: 'Status inválido',
        minLength: 'Status inválido'
      }
    },
    members: {
      locale_id: {
        null: 'Escolha um idioma',
        number: 'Idioma inválido'
      },
      image: {
        null: 'Imagem inválido',
        minLength: 'Imagem inválido'
      },
      name: {
        null: 'Digite o nome',
        minLength: 'Digite o nome'
      },
      role: {
        null: 'Digite a cargo',
        minLength: 'Digite a cargo'
      },
      text: {
        null: 'Digite o texto',
        minLength: 'Digite o texto'
      },
      linkedin: {
        null: 'Digite o linkedin',
        minLength: 'Digite o linkedin'
      },
      sort: {
        null: 'Ordem inválida',
        number: 'Ordem inválida',
        greaterThanOrEqual: 'Ordem inválida'
      },
      status: {
        null: 'Status inválido',
        minLength: 'Status inválido'
      }
    },
    categories: {
      locale_id: {
        null: 'Escolha um idioma',
        number: 'Idioma inválido'
      },
      name: {
        null: 'Digite o nome da categoria',
        minLength: 'Digite o nome da categoria'
      },
      description: {
        null: 'Digite a descrição da categoria',
        minLength: 'Digite a descrição da categoria'
      },
      status: {
        null: 'Status inválido',
        minLength: 'Status inválido'
      }
    },
    contents: {
      locale_id: {
        null: 'Escolha um idioma',
        number: 'Idioma inválido'
      },
      category_id: {
        null: 'Categoria inválida',
        number: 'Categoria inválida'
      },
      image: {
        null: 'Imagem é obrigatória',
        minLength: 'Imagem é obrigatória'
      },
      date: {
        null: 'Data inválida',
        validDate: 'Data inválida'
      },
      title: {
        null: 'Título é obrigatório',
        minLength: 'Título é obrigatório',
        maxLength: 'Máximo de 255 caracteres',
      },
      summary: {
        null: 'Resumo é obrigatório',
        minLength: 'Resumo é obrigatório'
      },
      text: {
        null: 'Texto é obrigatório',
        minLength: 'Texto é obrigatório'
      },
      status: {
        null: 'Status inválido',
        minLength: 'Status inválido'
      }
    },
    funds: {
      locale_id: {
        null: 'Escolha um idioma',
        number: 'Idioma inválido'
      },
      sort: {
        null: 'Ordem inválida',
        number: 'Ordem inválida',
        greaterThanOrEqual: 'Ordem inválida'
      },
      status: {
        null: 'Status inválido',
        minLength: 'Status inválido'
      }
    }
  },
  date: {
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNames: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    amPm: ['am', 'pm']
  }
}
