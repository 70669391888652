import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createCategory, loadCategories } from '../../store/actions/category'
import { notificate } from '../../store/actions/notification'
import { loadLocales } from '../../store/actions/locale'
import { BackToList, FormTips } from '../molecules'
import { CategoryForm } from '../organisms'
import { localeUtils } from '../../utils'

class CreateCategory extends Component {
  constructor(props) {
    super(props)
    const { token } = props.user.user
    props.loadLocales(token).then(() => {
      props.loadCategories(token, {
        options: true,
        locale_id: localeUtils.getMainID(this.props.locale.list)
      })
    })
  }

  render() {
    const { sending } = this.props.category
    return (
      <div>
        <BackToList to='categories' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de nova Categoria</h1>
            <CategoryForm
              sending={sending}
              onSubmit={this._handleSubmit}
              notificate={this._onNotificate}
              locales={this.props.locale.list}
              parents={this.props.category.list}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='category' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.user.user
    this.props.createCategory(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    category: state.category,
    locale: state.locale
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadCategories: (token, filters = {}) => dispatch(loadCategories(token, filters)),
    createCategory: (token, data) => dispatch(createCategory(token, data)),
    loadLocales: token => dispatch(loadLocales(token)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory)
