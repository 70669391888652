import { api } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect
} from './'

const identifier = 'locale'

const _setLocales = data => ({ type: 'SET_LOCALES', data })
const _setLocale = data => ({ type: 'SET_LOCALE', data })
const _updateLocale = data => ({ type: 'UPDATE_LOCALE', data })

export const loadLocales = (token, filters = {}) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.get('admin/locales', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setLocales(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadLocale = (token, id) => {
  return dispatch => {
    dispatch(_setLocale(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/locales/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setLocale(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateLocale = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.put(`admin/locales/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch(_updateLocale(result.data))
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'locales' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createLocale = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/locales', data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'locales' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const destroyLocale = (token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/locales/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
