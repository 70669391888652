import { api, listFilters } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect
} from './'

const identifier = 'fund'

const _setFunds = data => ({ type: 'SET_FUNDS', data })
const _setFundFilters = data => ({ type: 'SET_FUND_FILTERS', data })
const _setSheetFunds = data => ({ type: 'SET_SHEET_FUNDS', data })
const _setFund = data => ({ type: 'SET_FUND', data })
const _updateFund = data => ({ type: 'UPDATE_FUND', data })

export const loadSheetFunds = (token, filters = {}) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.get('admin/funds/sheet', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setSheetFunds(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadFunds = (token, filters = {}) => {
  return dispatch => {
    if (listFilters.saveFilters(filters)) {
      dispatch(_setFundFilters(filters))
    }
    dispatch(beforeSend(identifier))
    return api.get('admin/funds', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setFunds(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadFund = (token, id) => {
  return dispatch => {
    dispatch(_setFund(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/funds/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setFund(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateFund = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.put(`admin/funds/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch(_updateFund(result.data))
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'funds' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createFund = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/funds', data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'funds' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const destroyFund = (token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/funds/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
