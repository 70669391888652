import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Icon } from '../atoms'
import { Spinner, FileType } from '../molecules'
import {
  DataTable,
  Confirmation,
  UploadForm,
  ListFilters
} from '../organisms'
import {
  loadUploads,
  createUpload,
  destroyUpload
} from '../../store/actions/upload'
import { notificate } from '../../store/actions/notification'
import { date } from '../../utils'

class Uploads extends Component {
  state = {
    filters: {
      page: 1,
      limit: 10,
      search: ''
    },
    currentId: null
  }

  componentDidMount() {
    this._loadFilters(this._loadData)
  }

  render() {
    const { list, paging, sending } = this.props.upload
    const { currentId } = this.state
    const columns = this._getColumns()
    return (
      <div>
        <Confirmation
          opened={currentId !== null}
          onCancel={this._onCancel}
          onConfirm={this._onConfirm}
        />
        <div className="top-actions">
          <UploadForm
            ref={ref => this.uploadForm = ref}
            sending={sending}
            onSubmit={this._onSubmit}
            notificate={this._onNotificate}
          />
        </div>
        <ListFilters
          onChange={this._onFilter}
          data={this.props.upload.filters}
          filters={['search']}
        />
        {sending &&
          <div className="center">
            <Spinner />
          </div>
        }
        <DataTable
          loading={sending}
          columns={columns}
          rows={this._formatList(list)}
          paging={paging}
          onDelete={this._onDelete}
          onChangePage={this._onChangePage}
          onCopy={this._onCopy}
        />
      </div>
    )
  }

  _loadData = () => {
    const { token } = this.props.user.user
    const { filters } = this.state
    this.props.loadUploads(token, filters)
  }

  _getColumns = () => {
    return [{
      name: 'ID',
      field: 'id'
    }, {
      name: 'Thumb',
      field: 'file_thumb_url',
      filter: (value, row) => {
        if (!value) return <FileType type={row.file_type} />
        return (
          <img
            src={value}
            alt=''
            className="thumbnail"
            width="50"
          />
        )
      }
    }, {
      name: 'Arquivo',
      field: 'file_url',
      filter: value => {
        return (
          <div className="copy-upload-url">
            <CopyToClipboard
              text={value}
              onCopy={this._onCopy}
              className="copy-upload-url__button"
            >
              <Icon name="copy" />
            </CopyToClipboard>
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
            >{value}</a>
          </div>
        )
      }
    }, {
      name: 'Tipo',
      field: 'file_type'
    }, {
      name: 'Data',
      field: 'created_at',
      filter: value => date.formatDatetime(value)
    }]
  }

  _onDelete = item => {
    this.setState({ currentId: item.id })
  }

  _onCancel = () => {
    this.setState({ currentId: null })
  }

  _onConfirm = () => {
    const { token } = this.props.user.user
    const { currentId } = this.state
    this.setState({ currentId: null }, () => {
      this.props.destroyUpload(token, currentId).then(() => {
        this._reloadData()
      })
    })
  }

  _reloadData = () => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _onChangePage = data => {
    const filters = {
      ...this.state.filters,
      page: +data.selected + 1
    }
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _formatList = list => {
    return list.map(item => {
      return {
        ...item,
        statusFormated: (
          item.status === 'enabled'
            ? 'Sim'
            : 'Não'
        )
      }
    })
  }

  _onSubmit = data => {
    const promises = data.map(item => {
      const { token } = this.props.user.user
      return this.props.createUpload(token, item)
    })
    Promise.all(promises).then(() => {
      this.uploadForm.cleanData()
      this._loadData()
    })
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }

  _onFilter = values => {
    const filters = {
      ...this.state.filters,
      page: 1
    }
    values.forEach(item => {
      filters[item.filter] = item.value
    })
    this.setState({ filters }, () => {
      this._loadData()
    })
  }

  _loadFilters = callback => {
    if (this.props.upload.filters) {
      const filters = {
        ...this.state.filters,
        ...this.props.upload.filters
      }
      this.setState({ filters }, callback)
    } else {
      callback()
    }
  }

  _onCopy = () => {
    this.props.notificate('URL copiada!', 'info')
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    upload: state.upload
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadUploads: (token, filters) => dispatch(loadUploads(token, filters)),
    createUpload: (token, data) => dispatch(createUpload(token, data)),
    destroyUpload: (token, id) => dispatch(destroyUpload(token, id)),
    notificate: (message, type) => dispatch(notificate(message, type))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Uploads)
