import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '../atoms'
import { FormControl } from '../molecules'
import { loadLocales } from '../../store/actions/locale'
import { loadCategories } from '../../store/actions/category'

class ListFilters extends Component {
  static propTypes = {
    data: PropTypes.object,
    filters: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired
  }

  state = {
    model: {
      filters: []
    }
  }

  componentDidMount() {
    this.delayInterval = 0
    const { data } = this.props
    const model = {
      ...this.state.model,
      filters: this.props.filters.map(filter => {
        let value = ''
        if (data && data[filter]) {
          value = data[filter]
        }
        return { filter, value }
      })
    }
    this.setState({ model })
    if (this.props.filters.includes('locale_id')) {
      const { token } = this.props.user.user
      this.props.loadLocales(token)
    }
    if (this.props.filters.includes('category_id')) {
      const { token } = this.props.user.user
      this.props.loadCategories(token, {
        options: true
      })
    }
  }

  render() {
    return (
      <div className="list-filters">
        {this._getFilters()}
        <Button
          className="list-filters__clear-filters"
          size="small"
          onClick={this._clearFilters}
          disabled={!this._hasFilters()}
        >Limpar filtros</Button>
      </div>
    )
  }

  _getFilters = () => {
    const { model } = this.state
    return this.props.filters.map((filter, index) => {
      const filterValue = model.filters.find(item => {
        return item.filter === filter
      })
      switch (filter) {
        case 'search':
          return (
            <FormControl
              key={index}
              label='Filtrar por palavra-chave'
              value={filterValue ? filterValue.value : ''}
              onChange={value => this._onChange(filter, value)}
            />
          )

        case 'locale_id':
          return (
            <FormControl
              key={index}
              label='Filtrar pelo Idioma'
              type='select'
              options={this._getLocales()}
              value={filterValue ? filterValue.value : ''}
              onChange={value => this._onChange(filter, value)}
            />
          )

        case 'category_id':
          return (
            <FormControl
              key={index}
              label='Filtrar pela Categoria'
              type='select'
              options={this._getCategories()}
              value={filterValue ? filterValue.value : ''}
              onChange={value => this._onChange(filter, value)}
            />
          )

        default:
          return 'Invalid filter'
      }
    })
  }

  _onChange = (filter, value) => {
    const model = {
      ...this.state.model,
      filters: this.state.model.filters.map(item => {
        if (item.filter === filter) {
          item.value = value
        }
        return item
      })
    }
    if (filter === 'locale_id') {
      model.filters = model.filters.map(item => {
        if (item.filter === 'category_id') {
          item.value = ''
        }
        return item
      })
    }
    this.delayInterval = filter === 'search'
      ? 500
      : 0
    this.setState({ model }, this._sendChanges)
  }

  _sendChanges = () => {
    clearInterval(this.interval)
    this.interval = setTimeout(() => {
      this.props.onChange(this.state.model.filters)
    }, this.delayInterval)
  }

  _getLocales = () => {
    const firstOption = {
      value: '',
      label: 'Todos'
    }
    return [firstOption].concat(
      this.props.locale.list.map(item => {
        return {
          value: item.id,
          label: item.name
        }
      })
    )
  }

  _getCategories = () => {
    const firstOption = {
      value: '',
      label: 'Todas'
    }
    const { filters } = this.state.model
    let locale = filters.find(item => item.filter === 'locale_id')
    if (locale) {
      locale = +locale.value
    }
    return [firstOption].concat(
      this.props.category.list
        .filter(item => {
          if (locale) {
            return locale === +item.locale_id
          }
          return true
        })
        .map(item => {
          let label = `${item.name} (${item.locale.name})`
          if (locale) {
            label = item.name
          }
          return {
            value: item.id,
            label
          }
        })
    )
  }

  _hasFilters = () => {
    return this.state.model.filters
      .some(item => item.value)
  }

  _clearFilters = () => {
    const model = {
      ...this.state.model,
      filters: this.state.model.filters.map(item => {
        item.value = ''
        return item
      })
    }
    this.delayInterval = 0
    this.setState({ model }, this._sendChanges)
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    locale: state.locale,
    category: state.category
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadLocales: token => dispatch(loadLocales(token)),
    loadCategories: (token, filters) => dispatch(loadCategories(token, filters))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListFilters)
