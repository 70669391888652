import { combineReducers } from 'redux'
import user from './user'
import notification from './notification'
import base from './base'
import dashboard from './dashboard'
import mail from './mail'
import setting from './setting'
import upload from './upload'
import banner from './banner'
import locale from './locale'
import file from './file'
import platform from './platform'
import member from './member'
import category from './category'
import content from './content'
import fund from './fund'
import page from './page'
import contact from './contact'

export default combineReducers({
  user,
  notification,
  base,
  dashboard,
  mail,
  setting,
  upload,
  banner,
  locale,
  file,
  platform,
  member,
  category,
  content,
  fund,
  page,
  contact
})
