import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Header, Notification } from '../organisms'
import { withRouter } from 'react-router-dom'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import {
  setRedirect,
  setCleanRedirect,
  setCookie,
  setCleanCookie
} from '../../store/actions'
import { navigate, cookieSettings } from '../../utils'
import { HeaderMenu } from '../molecules'

class AppLayout extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  }

  componentDidUpdate (prevProps) {
    const { cookie } = this.props.base
    if (
      cookie !== prevProps.base.cookie &&
      cookie
    ) {
      const { cookies } = this.props
      if (cookie.action === 'set') {
        cookies.set(
          cookie.name,
          cookie.value,
          cookieSettings
        )
      } else if (cookie.action === 'remove') {
        cookies.remove(cookie.name, cookieSettings)
      }
      this.props.setCleanCookie()
    }

    if (
      this.props.base.redirect !== prevProps.base.redirect &&
      this.props.base.redirect
    ) {
      const { params } = this.props.base.redirect
      const screen = this.props.base.redirect.screen
      const { props } = this
      this.props.setCleanRedirect()
      navigate.to({ screen, params, props })
    }
  }

  render () {
    const { user } = this.props.user
    const classes = this._getClasses()
    return (
      <div className={classes}>
        <Notification />
        <div className="app-layout__header">
          <Header user={user} />
        </div>
        {user &&
          <div className="app-layout__sidebar">
            <HeaderMenu {...this.props} />
          </div>
        }
        <div className="app-layout__content">
          {this.props.children}
        </div>
      </div>
    )
  }

  _getClasses = () => {
    let classes = ['app-layout']
    const { user } = this.props.user
    if (user) {
      classes.push('app-layout--logged')
    }
    return classes.join(' ')
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    base: state.base
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRedirect: data => dispatch(setRedirect(data)),
    setCleanRedirect: () => dispatch(setCleanRedirect()),
    setCookie: (name, value) => dispatch(setCookie(name, value)),
    setCleanCookie: () => dispatch(setCleanCookie())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withCookies(
    withRouter(AppLayout)
  )
)
