import { api, listFilters } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect
} from './'

const identifier = 'platform'

const _setPlatforms = data => ({ type: 'SET_PLATFORMS', data })
const _setPlatformFilters = data => ({ type: 'SET_PLATFORM_FILTERS', data })
const _setPlatform = data => ({ type: 'SET_PLATFORM', data })
const _updatePlatform = data => ({ type: 'UPDATE_PLATFORM', data })

export const loadPlatforms = (token, filters = {}) => {
  return dispatch => {
    if (listFilters.saveFilters(filters)) {
      dispatch(_setPlatformFilters(filters))
    }
    dispatch(beforeSend(identifier))
    return api.get('admin/platforms', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setPlatforms(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadPlatform = (token, id) => {
  return dispatch => {
    dispatch(_setPlatform(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/platforms/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setPlatform(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updatePlatform = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.put(`admin/platforms/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch(_updatePlatform(result.data))
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'platforms' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createPlatform = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/platforms', data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'platforms' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const destroyPlatform = (token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/platforms/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
