import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '../molecules'
import { Button } from '../atoms'
import { platformValidator } from '../../validators'
import { form } from '../../utils'

export default class PlatformForm extends Component {
  static propTypes = {
    data: PropTypes.object,
    sending: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    notificate: PropTypes.func.isRequired
  }

  state = {
    model: {
      logo: '',
      name: '',
      link: '',
      is_main: false,
      sort: 1,
      status: true
    },
    errors: {}
  }

  fields = [{
    label: 'Logo',
    field: 'logo',
    type: 'image',
    props({ props }) {
      const { data } = props
      return {
        preview: data ? data.logo_url : null,
        onSelectFile: this._onSelectFile
      }
    }
  }, {
    label: 'Nome',
    field: 'name',
    type: 'text'
  }, {
    label: 'Link',
    field: 'link',
    type: 'text'
  }, {
    label: 'Principal (Plataforma Trigono Capital)',
    field: 'is_main',
    type: 'checkbox',
    props({ model }) {
      const checked = model.is_main
      return { checked }
    }
  }, {
    label: 'Ordem da Plafatorma',
    field: 'sort',
    type: 'text'
  }, {
    label: 'Ativo',
    field: 'status',
    type: 'switch',
    props({ model }) {
      const checked = model.status
      return { checked }
    }
  }]

  componentDidMount() {
    const { data } = this.props
    if (data) {
      const model = {
        ...this.state.model,
        ...data,
        status: data.status === 'active'
      }
      this.setState({ model })
    }
  }

  render() {
    const { sending } = this.props
    return (
      <form className="form" onSubmit={this._handleSubmit}>
        {this._getFields()}
        <Button
          type='submit'
          size='small'
          spinner={sending}
          disabled={sending}
        >Salvar</Button>
      </form>
    )
  }

  _getFields = () => {
    const { fields } = this
    const { model, errors } = this.state
    return fields.map((item, index) => {
      const value = model[item.field] || ''
      let otherProps = {}
      if (item.props) {
        otherProps = item.props.call(this, { props: this.props, model })
      }
      return (
        <FormControl
          {...otherProps}
          key={index}
          type={item.type}
          label={item.label}
          value={value}
          onChange={this._onChange(item.field)}
          errors={form.getErrors(item.field, errors)}
        />
      )
    })
  }

  _handleSubmit = event => {
    event.preventDefault()
    const data = {
      ...this.state.model,
      status: this.state.model.status ? 'active' : 'disabled'
    }
    const method = !this.props.data ? 'create' : 'edit'
    platformValidator[method](data).then(result => {
      if (result.success) {
        this.props.onSubmit(data)
      } else {
        this.setState({ errors: result.errors })
      }
    }).catch(error => {
      this.props.notificate(error.message)
    })
  }

  _onChange = field => {
    return value => {
      const numbers = ['locale_id', 'sort']
      if (numbers.includes(field) && value) {
        value = +value
      }
      const model = {
        ...this.state.model,
        [field]: value
      }
      this.setState({ model })
    }
  }

  _onSelectFile = file => {
    const { name } = file
    const extension = name.split('.').pop()
    const model = {
      ...this.state.model,
      file_type: extension
    }
    this.setState({ model })
  }
}
