const initialState = {
  user: null,
  data: null,
  list: [],
  paging: {},
  sending: false,
  checked: false
}

const identifier = 'user'

export default (state = initialState, action) => {
  let nextState = { ...state }

  switch (action.type) {
    case 'BEFORE_SEND':
      if (action.identifier === identifier) {
        nextState.sending = true
      }
      break

    case 'AFTER_RESPONSE':
      if (action.identifier === identifier) {
        nextState.sending = false
      }
      break

    case 'SET_USER':
      nextState = {
        ...state,
        user: action.user,
        checked: true
      }
      break

    case 'SET_LOGOUT':
      nextState = {
        ...state,
        user: null,
        checked: true
      }
      break

    case 'SET_USER_DATA':
      nextState.data = action.data
      break

    case 'SET_USERS':
      nextState.list = action.data.data
      nextState.paging = action.data.paging
      break

    default:
      nextState = state
      break
  }

  return nextState
}
