import React, { Component } from 'react'

export default class FundUploadSpreadsheet extends Component {
  render () {
    return (
      <label className={this._getClasses()}>
        <div className="button button--small fund-upload-spreadsheet__button">
          Upload Planilha
        </div>
        <input accept=".xlsx" className="fund-upload-spreadsheet__input" type="file" onChange={this._onUpload} />
      </label>
    )
  }

  _getClasses = () => {
    const { loading } = this.props
    let classes = ['fund-upload-spreadsheet']
    if (loading) {
      classes.push('fund-upload-spreadsheet--loading')
    }
    return classes.join(' ')
  }

  _onUpload = e => {
    const file = e.target.files[0]
    const extension = file.name.split('.').pop()
    if (extension === 'xlsx') {
      this.props.onChange(file)
    }
  }
}
