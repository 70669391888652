import { api, listFilters } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect
} from './'

const identifier = 'banner'

const _setBanners = data => ({ type: 'SET_BANNERS', data })
const _setBannerFilters = data => ({ type: 'SET_BANNER_FILTERS', data })
const _setBanner = data => ({ type: 'SET_BANNER', data })
const _updateBanner = data => ({ type: 'UPDATE_BANNER', data })

export const loadBanners = (token, filters = {}) => {
  return dispatch => {
    if (listFilters.saveFilters(filters)) {
      dispatch(_setBannerFilters(filters))
    }
    dispatch(beforeSend(identifier))
    return api.get('admin/banners', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setBanners(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadBanner = (token, id) => {
  return dispatch => {
    dispatch(_setBanner(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/banners/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setBanner(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateBanner = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.put(`admin/banners/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch(_updateBanner(result.data))
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'banners' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createBanner = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/banners', data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'banners' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const destroyBanner = (token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/banners/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
