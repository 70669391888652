import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '../molecules'
import { Button } from '../atoms'
import { fundValidator } from '../../validators'
import { form, localeUtils } from '../../utils'

export default class FundForm extends Component {
  static propTypes = {
    locales: PropTypes.arrayOf(PropTypes.object),
    parents: PropTypes.arrayOf(PropTypes.object),
    sheetFunds: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.object,
    sending: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    notificate: PropTypes.func.isRequired
  }

  state = {
    model: {
      locale_id: 1,
      parent_id: null,
      code: '',
      image: '',
      name: '',
      name_table: '',
      description: '',
      notes: '',
      notes_table: '',
      strategy: '',
      anbima_code: '',
      target_audience: '',
      investor_risk_profile: '',
      fund_risk_rating: '',
      risk_factors: '',
      minimum_movement: '',
      minimum_stay_balance: '',
      administration_fee: '',
      performance_fee: '',
      benchmark: '',
      entrance_fee: '',
      exit_fee: '',
      taxation: '',
      iof: '',
      apply_rescue_limit: '',
      apply_info: '',
      rescue_info: '',
      manager: '',
      administration: '',
      custody: '',
      audit: '',
      favored: '',
      regulation: '',
      monthly_report: '',
      documentation: '',
      sort: 1,
      status: true
    },
    errors: {}
  }

  fields = [{
    label: 'Idioma',
    field: 'locale_id',
    type: 'select'
  }, {
    label: 'Registro do Idioma Principal',
    field: 'parent_id',
    type: 'select'
  }, {
    label: 'Imagem',
    field: 'image',
    type: 'image',
    props({ props }) {
      const { data } = props
      return {
        preview: data ? data.image_url : null,
        onSelectFile: this._onSelectFile
      }
    }
  }, {
    label: 'Fundo de Investimento da Planilha',
    field: 'code',
    type: 'select'
  }, {
    label: 'Nome do Fundo de Investimento',
    field: 'name',
    type: 'text'
  }, {
    label: 'Nome do Fundo de Investimento 2 (* aparece em: E-mail Informativo / Home do Site / Lista de Fundos)',
    field: 'name_table',
    type: 'text'
  }, {
    label: 'Descrição',
    field: 'description',
    type: 'textarea'
  }, {
    label: 'Notas sobre o Fundo (* abaixo do Quero Investir)',
    field: 'notes',
    type: 'text'
  }, {
    label: 'Notas sobre o Fundo 2 (* aparece em: E-mail Informativo / Home do Site / Lista de Fundos)',
    field: 'notes_table',
    type: 'text'
  }, {
    label: 'Estratégia de Investimento',
    field: 'strategy',
    type: 'text'
  }, {
    label: 'Classificação ANBIMA',
    field: 'anbima_rating',
    type: 'text'
  }, {
    label: 'Código ANBIMA',
    field: 'anbima_code',
    type: 'text'
  }, {
    label: 'Público Alvo',
    field: 'target_audience',
    type: 'text'
  }, {
    label: 'Perfil de Risco do Investidor',
    field: 'investor_risk_profile',
    type: 'text'
  }, {
    label: 'Classificação de Risco do Fundo',
    field: 'fund_risk_rating',
    type: 'text'
  }, {
    label: 'Fatores de Risco',
    field: 'risk_factors',
    type: 'text'
  }, {
    label: 'Movimentação Mínima',
    field: 'minimum_movement',
    type: 'text'
  }, {
    label: 'Saldo Mínimo de Permanência',
    field: 'minimum_stay_balance',
    type: 'text'
  }, {
    label: 'Taxa de Administração',
    field: 'administration_fee',
    type: 'text'
  }, {
    label: 'Taxa de Performance',
    field: 'performance_fee',
    type: 'text'
  }, {
    label: 'Benchmark',
    field: 'benchmark',
    type: 'text'
  }, {
    label: 'Taxa de Entrada',
    field: 'entrance_fee',
    type: 'text'
  }, {
    label: 'Taxa de Saída',
    field: 'exit_fee',
    type: 'text'
  }, {
    label: 'Tributação',
    field: 'taxation',
    type: 'text'
  }, {
    label: 'IOF',
    field: 'iof',
    type: 'text'
  }, {
    label: 'Limite para Aplicação e Resgate',
    field: 'apply_rescue_limit',
    type: 'text'
  }, {
    label: 'Aplicação',
    field: 'apply_info',
    type: 'text'
  }, {
    label: 'Resgate',
    field: 'rescue_info',
    type: 'text'
  }, {
    label: 'Gestor',
    field: 'manager',
    type: 'text'
  }, {
    label: 'Administração',
    field: 'administration',
    type: 'text'
  }, {
    label: 'Custódia',
    field: 'custody',
    type: 'text'
  }, {
    label: 'Auditoria',
    field: 'audit',
    type: 'text'
  }, {
    label: 'Favorecido',
    field: 'favored',
    type: 'textarea'
  }, {
    label: 'Regulamento',
    field: 'regulation',
    type: 'file',
    props({ props }) {
      const { data } = props
      return {
        preview: data ? data.regulation_url : null
      }
    }
  }, {
    label: 'Relatório Mensal',
    field: 'monthly_report',
    type: 'file',
    props({ props }) {
      const { data } = props
      return {
        preview: data ? data.monthly_report_url : null
      }
    }
  }, {
    label: 'Documentação Legal',
    field: 'documentation',
    type: 'text'
  }, {
    label: 'Aviso Legal',
    field: 'legal_warning',
    type: 'text'
  }, {
    label: 'Ordem do fundo',
    field: 'sort',
    type: 'text'
  }, {
    label: 'Ativo',
    field: 'status',
    type: 'switch',
    props({ model }) {
      const checked = model.status
      return { checked }
    }
  }]

  componentDidMount() {
    const { data } = this.props
    if (data) {
      const model = {
        ...this.state.model,
        ...data,
        status: data.status === 'active'
      }
      this.setState({ model })
    }
  }

  render() {
    const { sending } = this.props
    return (
      <form className="form" onSubmit={this._handleSubmit}>
        {this._getFields()}
        <Button
          type='submit'
          size='small'
          spinner={sending}
          disabled={sending}
        >Salvar</Button>
      </form>
    )
  }

  _getFields = () => {
    const { fields } = this
    const { model, errors } = this.state
    return fields
      .filter(this._filterFields)
      .map((item, index) => {
        const value = model[item.field] || ''
        let otherProps = {}
        if (item.props) {
          otherProps = item.props.call(this, { props: this.props, model })
        }
        if (item.field === 'locale_id') {
          otherProps.options = [{
            value: '',
            label: 'Selecione um idioma'
          }].concat(
            this.props.locales.map(item => {
              return {
                value: item.id,
                label: item.name
              }
            })
          )
        }
        if (item.field === 'code') {
          otherProps.options = [{
            value: '',
            label: 'Selecione um Fundo'
          }].concat(
            this.props.sheetFunds.map(item => {
              return {
                value: item.code,
                label: item.name
              }
            })
          )
        }
        if (item.field === 'parent_id') {
          otherProps.options = [{
            value: '',
            label: 'Selecione um registro'
          }].concat(
            this.props.parents
              .filter(item => {
                if (this.props.data) {
                  return +this.props.data.id !== +item.id
                }
                return true
              })
              .map(item => {
                return {
                  value: item.id,
                  label: item.name
                }
              })
          )
        }
        return (
          <FormControl
            {...otherProps}
            key={index}
            type={item.type}
            label={item.label}
            value={value}
            onChange={this._onChange(item.field)}
            errors={form.getErrors(item.field, errors)}
          />
        )
      })
  }

  _handleSubmit = event => {
    event.preventDefault()
    const data = {
      ...this.state.model,
      status: this.state.model.status ? 'active' : 'disabled'
    }
    const method = !this.props.data ? 'create' : 'edit'
    fundValidator[method](data).then(result => {
      if (result.success) {
        this.props.onSubmit(data)
      } else {
        this.setState({ errors: result.errors })
      }
    }).catch(error => {
      this.props.notificate(error.message)
    })
  }

  _onChange = field => {
    return value => {
      const numbers = ['locale_id', 'sort']
      if (numbers.includes(field) && value) {
        value = +value
      }
      const model = {
        ...this.state.model,
        [field]: value
      }
      if (
        field === 'locale_id' &&
        localeUtils.isMain(this.props.locales, value)
      ) {
        model.parent_id = null
      }
      this.setState({ model })
    }
  }

  _onSelectFile = file => {
    const { name } = file
    const extension = name.split('.').pop()
    const model = {
      ...this.state.model,
      file_type: extension
    }
    this.setState({ model })
  }

  _filterFields = field => {
    if (field.field === 'parent_id') {
      const isMain = localeUtils.isMain(
        this.props.locales,
        this.state.model.locale_id
      )
      return !isMain
    }
    return true
  }
}
