import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createUser } from '../../store/actions/user'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { UserForm } from '../organisms'

class CreateUser extends Component {
  render() {
    const { sending } = this.props.user
    return (
      <div>
        <BackToList to='users' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de novo Usuário</h1>
            <UserForm
              sending={sending}
              onSubmit={this._handleSubmit}
              notificate={this._onNotificate}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='user' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.user.user
    this.props.createUser(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (token, data) => dispatch(createUser(token, data)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser)
