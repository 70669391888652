import v8n from 'v8n'
import validatorUtils from './validation_utils'

const identifier = 'contents'

export default {
  create(data) {
    return v8n()
      .schema({
        locale_id: v8n().not.null().number(),
        category_id: v8n().not.null().number(),
        date: v8n().not.null().validDate(),
        title: v8n().not.null().string().minLength(2).maxLength(255),
        summary: v8n().not.null().string().minLength(2),
        text: v8n().not.null().string().minLength(2),
        status: v8n().not.null().string().minLength(2).maxLength(255)
      })
      .testAsync(data)
      .then(result => ({ success: true }))
      .catch(e => validatorUtils.buildErrors(identifier, e))
  },

  edit(data) {
    return v8n()
      .schema({
        locale_id: v8n().not.null().number(),
        category_id: v8n().not.null().number(),
        date: v8n().not.null().validDate(),
        title: v8n().not.null().string().minLength(2).maxLength(255),
        summary: v8n().not.null().string().minLength(2),
        text: v8n().not.null().string().minLength(2),
        status: v8n().not.null().string().minLength(2).maxLength(255)
      })
      .testAsync(data)
      .then(result => ({ success: true }))
      .catch(e => validatorUtils.buildErrors(identifier, e))
  }
}
