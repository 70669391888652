import { api, listFilters } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse,
  setRedirect
} from './'

const identifier = 'file'

const _setFiles = data => ({ type: 'SET_FILES', data })
const _setFileFilters = data => ({ type: 'SET_FILE_FILTERS', data })
const _setFile = data => ({ type: 'SET_FILE', data })
const _updateFile = data => ({ type: 'UPDATE_FILE', data })

export const loadFiles = (token, filters = {}) => {
  return dispatch => {
    if (listFilters.saveFilters(filters)) {
      dispatch(_setFileFilters(filters))
    }
    dispatch(beforeSend(identifier))
    return api.get('admin/files', filters, token)
      .then(result => {
        if (result.success) {
          dispatch(_setFiles(result))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const loadFile = (token, id) => {
  return dispatch => {
    dispatch(_setFile(null))
    dispatch(beforeSend(identifier))
    return api.get(`admin/files/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(_setFile(result.data))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const updateFile = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.put(`admin/files/${data.id}`, data, token)
      .then(result => {
        if (result.success) {
          dispatch(_updateFile(result.data))
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'files' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const createFile = (token, data) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.post('admin/files', data, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
          dispatch(setRedirect({ screen: 'files' }))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const destroyFile = (token, id) => {
  return dispatch => {
    dispatch(beforeSend(identifier))
    return api.delete(`admin/files/${id}`, {}, token)
      .then(result => {
        if (result.success) {
          dispatch(notificate(result.message, 'info'))
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
