import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createFile, loadFiles } from '../../store/actions/file'
import { notificate } from '../../store/actions/notification'
import { loadLocales } from '../../store/actions/locale'
import { BackToList, FormTips } from '../molecules'
import { FileForm } from '../organisms'
import { localeUtils } from '../../utils'

class CreateFile extends Component {
  constructor(props) {
    super(props)
    const { token } = props.user.user
    props.loadLocales(token).then(() => {
      props.loadFiles(token, {
        options: true,
        locale_id: localeUtils.getMainID(this.props.locale.list)
      })
    })
  }

  render() {
    const { sending } = this.props.file
    return (
      <div>
        <BackToList to='files' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de novo Manual/Documento</h1>
            <FileForm
              sending={sending}
              onSubmit={this._handleSubmit}
              notificate={this._onNotificate}
              locales={this.props.locale.list}
              parents={this.props.file.list}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='file' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.user.user
    this.props.createFile(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    file: state.file,
    locale: state.locale
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadFiles: (token, filters = {}) => dispatch(loadFiles(token, filters)),
    createFile: (token, data) => dispatch(createFile(token, data)),
    loadLocales: token => dispatch(loadLocales(token)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFile)
