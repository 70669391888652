import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '../molecules'
import { Button } from '../atoms'
import { categoryValidator } from '../../validators'
import { form, localeUtils } from '../../utils'

export default class CategoryForm extends Component {
  static propTypes = {
    locales: PropTypes.arrayOf(PropTypes.object),
    parents: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.object,
    sending: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    notificate: PropTypes.func.isRequired
  }

  state = {
    model: {
      locale_id: 1,
      parent_id: null,
      name: '',
      description: '',
      status: true
    },
    errors: {}
  }

  fields = [{
    label: 'Idioma',
    field: 'locale_id',
    type: 'select'
  }, {
    label: 'Registro do Idioma Principal',
    field: 'parent_id',
    type: 'select'
  }, {
    label: 'Nome da categoria',
    field: 'name',
    type: 'text'
  }, {
    label: 'Descrição da categoria',
    field: 'description',
    type: 'text'
  }, {
    label: 'Ativo',
    field: 'status',
    type: 'switch',
    props({ model }) {
      const checked = model.status
      return { checked }
    }
  }]

  componentDidMount() {
    const { data } = this.props
    if (data) {
      const model = {
        ...this.state.model,
        ...data,
        status: data.status === 'active'
      }
      this.setState({ model })
    }
  }

  render() {
    const { sending } = this.props
    return (
      <form className="form" onSubmit={this._handleSubmit}>
        {this._getFields()}
        <Button
          type='submit'
          size='small'
          spinner={sending}
          disabled={sending}
        >Salvar</Button>
      </form>
    )
  }

  _getFields = () => {
    const { fields } = this
    const { model, errors } = this.state
    return fields
      .filter(this._filterFields)
      .map((item, index) => {
        const value = model[item.field] || ''
        let otherProps = {}
        if (item.props) {
          otherProps = item.props.call(this, { props: this.props, model })
        }
        if (item.field === 'locale_id') {
          otherProps.options = [{
            value: '',
            label: 'Selecione um idioma'
          }].concat(
            this.props.locales.map(item => {
              return {
                value: item.id,
                label: item.name
              }
            })
          )
        }
        if (item.field === 'parent_id') {
          otherProps.options = [{
            value: '',
            label: 'Selecione um registro'
          }].concat(
            this.props.parents
              .filter(item => {
                if (this.props.data) {
                  return +this.props.data.id !== +item.id
                }
                return true
              })
              .map(item => {
                return {
                  value: item.id,
                  label: item.name
                }
              })
          )
        }
        return (
          <FormControl
            {...otherProps}
            key={index}
            type={item.type}
            label={item.label}
            value={value}
            onChange={this._onChange(item.field)}
            errors={form.getErrors(item.field, errors)}
          />
        )
      })
  }

  _handleSubmit = event => {
    event.preventDefault()
    const data = {
      ...this.state.model,
      status: this.state.model.status ? 'active' : 'disabled'
    }
    const method = !this.props.data ? 'create' : 'edit'
    categoryValidator[method](data).then(result => {
      if (result.success) {
        this.props.onSubmit(data)
      } else {
        this.setState({ errors: result.errors })
      }
    }).catch(error => {
      this.props.notificate(error.message)
    })
  }

  _onChange = field => {
    return value => {
      const numbers = ['locale_id']
      if (numbers.includes(field) && value) {
        value = +value
      }
      const model = {
        ...this.state.model,
        [field]: value
      }
      if (
        field === 'locale_id' &&
        localeUtils.isMain(this.props.locales, value)
      ) {
        model.parent_id = null
      }
      this.setState({ model })
    }
  }

  _onSelectFile = file => {
    const { name } = file
    const extension = name.split('.').pop()
    const model = {
      ...this.state.model,
      file_type: extension
    }
    this.setState({ model })
  }

  _filterFields = field => {
    if (field.field === 'parent_id') {
      const isMain = localeUtils.isMain(
        this.props.locales,
        this.state.model.locale_id
      )
      return !isMain
    }
    return true
  }
}
