import { api } from '../../utils'
import { notificate } from './notification'
import {
  beforeSend,
  afterResponse
} from './'

const identifier = 'mail'

const _setMailTest = data => ({ type: 'SET_MAIL_TEST', data })
const _setSendMail = data => ({ type: 'SET_SEND_MAIL', data })
const _setExportEmails = data => ({ type: 'SET_EXPORT_EMAILS', data })

export const mailTest = (token, params = {}) => {
  return dispatch => {
    dispatch(_setMailTest(null))
    const data = {
      ...params,
      template: 'fund_performance'
    }
    dispatch(beforeSend(identifier))
    return api.post('admin/mail/test', data, token)
      .then(result => {
        dispatch(_setMailTest(result))
        dispatch(afterResponse(identifier))
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const sendMail = (token, params = {}) => {
  return dispatch => {
    dispatch(_setSendMail(null))
    dispatch(beforeSend(identifier))
    return api.post('admin/emails/send', params, token)
      .then(result => {
        let output = false
        if (result.success) {
          dispatch(_setSendMail(result))
          dispatch(afterResponse(identifier))
          output = true
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
        return output
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}

export const exportEmails = token => {
  return dispatch => {
    dispatch(_setExportEmails(null))
    dispatch(beforeSend(identifier))
    return api.post('admin/emails/export', {}, token)
      .then(result => {
        let output = false
        if (result.success) {
          dispatch(_setExportEmails(result.data))
          dispatch(afterResponse(identifier))
          output = true
        } else {
          dispatch(notificate(result.message, 'error'))
        }
        dispatch(afterResponse(identifier))
        return output
      })
      .catch(error => {
        dispatch(notificate(error.message, 'error'))
        dispatch(afterResponse(identifier))
      })
  }
}
