import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import routes from './routes'
import { checkLogin } from './store/actions/user'
import { AppLayout } from './components/templates'
import { cookieSettings } from './utils'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
      rest.isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to={{
            pathname: '/login?location=' + props.location.pathname
          }} />
    )} />
  )
}

class App extends Component {
  componentDidMount () {
    const { user } = this.props.user
    const { checkLogin, cookies } = this.props
    if (!user) {
      const token = cookies.get('token', cookieSettings)
      const { screen, params } = this._getRedirect()
      checkLogin(token, screen, params)
    }
  }

  render() {
    const { user } = this.props.user
    const isAuthenticated = user !== null
    return (
      <BrowserRouter>
        <AppLayout>
          <Switch>
            {routes.routes.map(route => {
              if (route.requiresAuth) {
                return (
                  <PrivateRoute
                    exact
                    isAuthenticated={isAuthenticated}
                    key={route.name}
                    path={route.path}
                    component={route.component}
                  />
                )
              } else {
                return (
                  <Route
                    exact
                    key={route.name}
                    path={route.path}
                    component={route.component}
                  />
                )
              }
            })}
          </Switch>
        </AppLayout>
      </BrowserRouter>
    )
  }

  _getRedirect = () => {
    let screen = 'dashboard'
    let params = {}
    let location = document.location.toString()
    if (location.includes('?location=')) {
      location = location.split('?location=')[1]
      const blockeds = [
        'dashboard',
        'login',
        'createAccount',
        'recoveryPassword',
        'passwordChange'
      ]
      const lastLocationParts = location.split('/')
      const lastLocationPart = +(lastLocationParts.pop())
      routes.routes.forEach(route => {
        if (location === route.path) {
          screen = route.name
        } else if (lastLocationPart > 0) {
          const checkLocation = `${lastLocationParts.join('/')}/:id`
          if (checkLocation === route.path) {
            screen = route.name
            params = { id: lastLocationPart }
          }
        }
      })
      if (blockeds.includes(screen)) {
        screen = 'dashboard'
      }
    }
    return { screen, params }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkLogin: (token, screen, params) => dispatch(checkLogin(token, screen, params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withCookies(App)
)
