import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadContents, createContent } from '../../store/actions/content'
import { notificate } from '../../store/actions/notification'
import { loadLocales } from '../../store/actions/locale'
import { loadCategories } from '../../store/actions/category'
import { loadMembers } from '../../store/actions/member'
import { BackToList, FormTips } from '../molecules'
import { ContentForm } from '../organisms'
import { localeUtils } from '../../utils'

class CreateContent extends Component {
  constructor(props) {
    super(props)
    const { token } = props.user.user
    props.loadLocales(token).then(() => {
      props.loadContents(token, {
        options: true,
        locale_id: localeUtils.getMainID(this.props.locale.list)
      })
    })
    props.loadCategories(token, {
      options: true,
      locale_id: 1
    })
    props.loadMembers(token, {
      options: true,
      locale_id: 1
    })
  }

  render() {
    const { sending } = this.props.content
    return (
      <div>
        <BackToList to='contents' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Cadastro de novo Conteúdo</h1>
            <ContentForm
              sending={sending}
              onSubmit={this._handleSubmit}
              onChangeLocale={this._onChangeLocale}
              notificate={this._onNotificate}
              locales={this.props.locale.list}
              parents={this.props.content.list}
              categories={this.props.category.list}
              members={this.props.member.list}
            />
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='content' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.user.user
    this.props.createContent(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }

  _onChangeLocale = localeId => {
    const { token } = this.props.user.user
    this.props.loadCategories(token, {
      options: true,
      locale_id: localeId
    })
    this.props.loadMembers(token, {
      options: true,
      locale_id: localeId
    })
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    content: state.content,
    locale: state.locale,
    category: state.category,
    member: state.member
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadContents: (token, filters = {}) => dispatch(loadContents(token, filters)),
    createContent: (token, data) => dispatch(createContent(token, data)),
    loadLocales: token => dispatch(loadLocales(token)),
    loadCategories: (token, filters) => dispatch(loadCategories(token, filters)),
    loadMembers: (token, filters) => dispatch(loadMembers(token, filters)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateContent)
