import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '../molecules'

class SettingItem extends Component {
  static props = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    isPublic: PropTypes.boolean,
    localeId: PropTypes.number,
    sending: PropTypes.boolean
  }

  constructor(props) {
    super(props)
    this.state = {
      newValue: props.value
    }
  }

  render () {
    const { newValue } = this.state
    const { sending, isPublic } = this.props
    const classes = this._getClasses()
    const description = this._getDescription()
    const icon = isPublic ? null : 'lock'
    return (
      <div className={classes}>
        <FormControl
          label={description}
          icon={icon}
          value={newValue}
          disabled={sending}
          onChange={this._onChange}
          onKeyUp={this._checkEnter}
          onBlur={this._sendChanges}
        />
      </div>
    )
  }

  _getClasses = () => {
    let classes = ['setting-item']
    return classes.join(' ')
  }

  _onChange = newValue => {
    this.setState({ newValue })
  }

  _checkEnter = event => {
    if (event.keyCode === 13) {
      this._sendChanges()
    }
  }

  _sendChanges = () => {
    const { newValue } = this.state
    const { id, name, description, localeId, isPublic } = this.props
    const data = {
      id,
      name,
      description,
      public: isPublic,
      locale_id: localeId,
      value: newValue
    }
    this.props.onChange(data)
  }

  _getDescription = () => {
    const { description } = this.props
    const parts = description.split('-')
    parts.shift()
    return parts.join('-').trim()
  }
}

export default SettingItem
