import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadUser, updateUser } from '../../store/actions/user'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips } from '../molecules'
import { UserForm } from '../organisms'

class EditUser extends Component {
  constructor(props) {
    super(props)
    const { token } = props.user.user
    const { id } = props.match.params
    props.loadUser(token, id)
  }

  render() {
    const { data, sending } = this.props.user
    return (
      <div>
        <BackToList to='users' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Editar Usuário</h1>
            {data &&
              <UserForm
                data={data}
                sending={sending}
                onSubmit={this._handleSubmit}
                notificate={this._onNotificate}
              />
            }
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='user' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.user.user
    this.props.updateUser(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadUser: (token, id) => dispatch(loadUser(token, id)),
    updateUser: (token, data) => dispatch(updateUser(token, data)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser)
