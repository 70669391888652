import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadPlatform, updatePlatform } from '../../store/actions/platform'
import { notificate } from '../../store/actions/notification'
import { BackToList, FormTips, Spinner } from '../molecules'
import { PlatformForm } from '../organisms'

class EditPlatform extends Component {
  constructor(props) {
    super(props)
    const { token } = props.user.user
    const { id } = props.match.params
    props.loadPlatform(token, id)
  }

  render() {
    const { data, sending } = this.props.platform
    return (
      <div>
        <BackToList to='platforms' />
        <div className="form-and-tips">
          <div className="form-and-tips__form">
            <h1 className="page-title">Editar Plataforma</h1>
            {!data && sending &&
              <Spinner />
            }
            {data &&
              <PlatformForm
                data={data}
                sending={sending}
                onSubmit={this._handleSubmit}
                notificate={this._onNotificate}
              />
            }
          </div>
          <div className="form-and-tips__tips">
            <FormTips name='platform' />
          </div>
        </div>
      </div>
    )
  }

  _handleSubmit = data => {
    const { token } = this.props.user.user
    this.props.updatePlatform(token, data)
  }

  _onNotificate = message => {
    this.props.notificate(message)
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    platform: state.platform
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadPlatform: (token, id) => dispatch(loadPlatform(token, id)),
    updatePlatform: (token, data) => dispatch(updatePlatform(token, data)),
    notificate: message => dispatch(notificate(message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPlatform)
